import React from 'react';
import PropTypes from 'prop-types';

const DeliveryFormats = props => {
  let { hasMobileNotification:n, hasBanner:b, hasMessage:m } = props;

  // now the message (fulltext) is manditory (therefore making true regardless of props) and we are showing the optional and manditory delivery formats
  m = true
  
  return (
    <div>
      {!(n || b || m) && <span>(none)</span>}
      {n && <span>Mobile Notification</span>}
      {n && b && <span>, </span>}
      {b && <span>Banner</span>}
      {(n || b) && m && <span>, </span>}
      {m && <span>Message</span>}
    </div>
  );
};

DeliveryFormats.defaultProps = {
  hasMessage: false 
};

DeliveryFormats.propTypes = {
  hasMobileNotification: PropTypes.bool.isRequired,
  hasBanner: PropTypes.bool.isRequired,
  hasMessage: PropTypes.bool // not required
};

export default DeliveryFormats;
