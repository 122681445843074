import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import {
  Popper,
  Typography,
  Paper,
  Fade,
  Button,
  Table,
  TableRow,
  Box,
  TableCell,
  TableBody,
  Divider
} from '@material-ui/core';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';

const useStyles = makeStyles(theme => ({
  frame: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  paper: {
    flex: 1,
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  table: {},
  row: {},
  cell: {
    paddingLeft: 0,
    paddingRight: '0px !important' // was being overridden by material-table styles (on last child TableCell)
  },
  heading: {
    color: theme.palette.primary.light
  },
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  }
}));

const DetailsOfCustomer = props => {
  const { history, customerFields, customer, handleClose } = props;
  const classes = useStyles();

  return (
    <CustomPaper narrow>
      <CustomHeading>
        User Details
      </CustomHeading>
      <ul className={classes.ul}>
          {customerFields.map((item, index) => {
            const fieldData = customer[item.field];
            return (
              fieldData && (
                <CustomDataItem key={item.field} title={item.title} data={fieldData} />
              )
            );
          })}
          <CustomDataItem title={'Account Number'} data={''} />
          <CustomDataItem title={'Program'} data={''} />
          <CustomDataItem title={'Date Joined'} data={''} />
          <CustomDataItem title={'Last Active'} data={''} />
          <CustomDataItem title={'Engagement Score'} data={''} />
      </ul>
    </CustomPaper>
  );
};

DetailsOfCustomer.propTypes = {
  history: PropTypes.object
};

DetailsOfCustomer.defaultProps = {
  history: {}
};

export default DetailsOfCustomer;