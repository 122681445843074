import moment from 'moment-timezone';

export const makeDefaultLabel = () => {
  const dateString = moment().format('YYYY-MM-DD');
  return `${dateString} New Message`
};

export const buttonLabelOptions = [
  'DISMISS',
  'DISLIKE',
  'LEARN MORE',
  'LIKE',
  'OPEN',
]

export const DefaultUiButton = {
  "action": "notGiven",
  "enable": false,
  "label": "notGiven",
  "type": "button",
  "url": "notGiven",
  "value": "notGiven"
}

export const defaultUiTexts = {
  fullText: "",
  notificationText: "",
  subjectText: "",
  summaryText: "",
  teaserText: ""
}

export const defaultUiImages = {
  bannerImage: "",
  fullImage: "",
  logoImage: "",
  teaserImage: ""
}

export const defaultDeliveryFormats = {
  emailNotification: false,
  smsNotification: false,
  osNotification: false,
  banner: false,
  messageBody: true, // message body is manditory
}