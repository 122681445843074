export const sidebarAuth = [
  'HOME',
  'CUSTOMERS',
  'MESSAGE_CENTER',
  'INSIGHTS',
  'ENERGY_PROGRAMS',
  'SETTINGS',
  'DOWNLOADS',
  'HELP',
  'ADMIN'
];
export const sidebarUnAuth = ['LANDING'];
