/* 
  this text editor has gotten way too complicated.  
  
  In retrospect, what I would prefer is a basic multiline textbox.
  there would be buttons above the textbox for list, link, etc... and when one of these buttons is pushed
  a corresponding string woud be appended to the text.  It's very clear to the user what's happening and 
  the user has more freedom once they grok the markdown syntax.

  Also, the functions like setDraftNow etc... aren't used.  I don't consider that pattern (having the state of the editor component 
  seperate from the state of the parent) to be stable.  And besides, it turns out I needed to convert to markdown after every 
  keystroke anyway, in order to accurately count the characters.  On the other hand though, it might create unnessesary re-renders
  because every change causes a change to the input
*/
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import { makeStyles } from '@material-ui/core/styles';
import './react-draft-wysiwyg.css'; // copied from '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import classNames from 'classnames';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'; // for converting text state object to markdown

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 32
  },
  characterLimit: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 40
  },
  toolbarClassName: {
    border: `1px solid ${theme.palette.primary.highLight}`,
    display: 'flex'
  },
  wrapperClassName: {
    margin: 5,
    padding: 5,
    // marginBottom: 0,
    // paddingBottom: 0,
    border: `1px solid ${theme.palette.primary.highLight}`
  },
  editorClassName: {
    border: `1px solid ${theme.palette.primary.highLight}`
    // height: 300,
  }
}));

const toolbar = {
  options: [
    'inline',
    'blockType',
    // 'fontSize',
    // 'fontFamily',
    'list',
    'textAlign',
    // 'colorPicker',
    'link',
    // 'embedded',
    // 'emoji',
    // 'image',
    // 'remove',
    'history'
  ],
  inline: {
    options: [
      'bold',
      'italic',
      'underline'
      // 'strikethrough',
      // 'monospace',
      // 'superscript',
      // 'subscript'
    ]
  },
  blockType: {
    options: [
      'Normal',
      // 'H1', 'H2', 'H3', 'H4', 'H5', 'H6',
      'Blockquote'
      // 'Code'
    ]
  },
  // fontSize: {
  //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
  // },
  // fontFamily: {
  //   options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
  // },
  list: {
    options: [
      'unordered',
      'ordered'
      // 'indent',
      // 'outdent'
    ]
  },
  textAlign: {
    options: ['left', 'center', 'right', 'justify']
  }
};

// a basic text editor
// props:
// -- toolbar, wrapper, editor: add class names to allow styling
// -- simple: (bool) turns the editor into a simple textbox with no toolbar
// -- setDraft
// -- setMarkdown
// -- setDraftNow: (bool)
// -- setMarkdownNow: (bool)
const TextEditor = props => {
  const {
    toolbar: t,
    wrapper,
    editor,
    simple = false,
    // outputs: { setDraft, setMarkdown, setDraftNow = false, setMarkdownNow = false } = {},
    maxLength = 100,
    markdown = '',
    setMarkdown = () => { },
    disabled = false,
  } = props;
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(markdownToDraft(markdown))));
  // convertFromRaw(markdownToDraft(markdownInput)) // EditorState.createEmpty() // EditorState.createWithContent(ContentState.createFromText('Hello'))
  const [length, setLength] = useState(0);
  const classes = useStyles();

  const onEditorStateChange = state => {
    if (!disabled) {
      const newMarkdown = draftToMarkdown(convertToRaw(state.getCurrentContent()));
      const chars = newMarkdown.length;
      setLength(chars);
      if (chars <= maxLength) {
        setMarkdown(newMarkdown);
        setEditorState(state);
    }
    // console.log(markdown);
    }
    // console.log(state.getCurrentContent().getPlainText())
    // console.log(convertToRaw(state.getCurrentContent()))
    // console.log(draftToMarkdown(convertToRaw(state.getCurrentContent())))
    // console.log(markdownToDraft(draftToMarkdown(convertToRaw(state.getCurrentContent()))))
    // console.log(convertFromRaw(markdownToDraft(draftToMarkdown(convertToRaw(state.getCurrentContent())))).getPlainText())
    // console.log(markdownToDraft(draftToMarkdown(convertToRaw(state.getCurrentContent()))))
  };

  // if (setDraftNow) {
  //   setDraft(convertToRaw(editorState._immutable.currentContent));
  // }
  // if (setMarkdownNow) {
  //   setMarkdown(draftToMarkdown(convertToRaw(editorState._immutable.currentContent)));
  // }

  return (
    <div className={classes.root}>
      <Editor
        editorState={editorState}
        toolbar={simple ? { options: [] } : toolbar}
        toolbarHidden={simple ? true : false}
        toolbarClassName={classNames('toolbarClassName', classes.toolbarClassName, { [t]: t })}
        wrapperClassName={classNames('wrapperClassName', classes.wrapperClassName, {
          [wrapper]: wrapper
        })}
        editorClassName={classNames('editorClassName', classes.editorClassName, {
          [editor]: editor
        })}
        onEditorStateChange={onEditorStateChange}
      />
      <div className={classes.characterLimit}>
        <div><span>{length}/{maxLength}</span></div>
      </div>
    </div>
  );
};

export default TextEditor;
