import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';
import { QUERY_MESSAGE, QUERY_DRAFT } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';
import Create from 'screens/MessageCenter/Create';
import { ROUTES } from 'components/Routes';
import { DefaultUiButton, defaultDeliveryFormats } from 'constants/messageUiDefaults';

// this component takes the mode and the inputID, does the graphQL query to get the message or draft,
// processes it, and passes it to the Create component.
const CreateGQLWrapper = props => {
  const {
    history: {
      push,
      location: {
        state: {
          screen = 'NEW'
        } = {}
      } = {}
    } = {},
    inputId = null } = props

  // console.log(screen);

  const {
    loading: loadMessageQueryLoading,
    error: loadMessageQueryError,
    data: loadMessageQueryData
  } = useQuery(QUERY_MESSAGE, { variables: { id: inputId }, skip: (screen !== 'EDIT') });
  const {
    loading: loadDraftQueryLoading,
    error: loadDraftQueryError,
    data: loadDraftQueryData
  } = useQuery(QUERY_DRAFT, { variables: { id: inputId }, skip: (screen !== 'CREATE') });

  if (loadMessageQueryError) {
    // console.log(loadMessageQueryError);
    alert('Error loading message');
    let redirectPath = ROUTES.MESSAGE_CENTER.screens.INDEX;
    push(redirectPath.pathname, redirectPath.state);
  }
  if (loadDraftQueryError) {
    // console.log(loadDraftQueryError);
    alert('Error loading draft');
    let redirectPath = ROUTES.MESSAGE_CENTER.screens.INDEX;
    push(redirectPath.pathname, redirectPath.state);
  }

  let loadedMessage;
  if (loadDraftQueryData) {
    ({ drafts: [loadedMessage] = [] } = loadDraftQueryData);
    // console.log(loadedMessage);
  }
  if (loadMessageQueryData) {
    ({ messages: [loadedMessage] = [] } = loadMessageQueryData);
    // console.log(loadedMessage);
  }

  switch (screen) {
    case 'EDIT': 
    case 'CREATE':
      if (!_.isNil(loadedMessage)) {
        // console.log(loadedMessage);
        let {
          messageLabel = '',
          images = {},
          texts = {},
          deliveryFormats = defaultDeliveryFormats,
          buttons = [],
          users = []
        } = loadedMessage;
        if (_.isNil(deliveryFormats)) {
          deliveryFormats = defaultDeliveryFormats;
        }
        if (_.isNil(texts)) {
          texts = {}
        }
        if (_.isNil(images)) {
          images = {}
        }
        if (_.isNil(buttons)) {
          buttons = []
        }

        // process the message to replace the "notGiven" and null values with empty strings
        let processedMessage = {};
        let processedMessageImages = {};
        let processedMessageTexts = {};
        _.map(images, (value, key) => {
          if (key !== '__typename') {
            if (value === 'notGiven' || _.isNil(value)) {
              processedMessageImages[key] = '';
            } else {
              processedMessageImages[key] = value;
            }
          }
        });
        _.map(texts, (value, key) => {
          if (key !== '__typename') {
            if (value === 'notGiven' || _.isNil(value)) {
              processedMessageTexts[key] = '';
            } else {
              processedMessageTexts[key] = value;
            }
          }
        });

        // also, for the buttons, replace notGiven url values with empty string
        // and set there to be two buttons reflecting the ui
        let processedButtons = [];
        if (!_.isNil(buttons) && Array.isArray(buttons)) {
          processedButtons = buttons.map(item => {
            const { url } = item;
            let newUrl = url;
            if (url === 'notGiven') {
              newUrl = '';
            }
            return { ...item, url: newUrl };
          })
        } else {
          processedButtons = [DefaultUiButton, DefaultUiButton];
        };
        if (processedButtons.length === 0) {
          processedButtons = [DefaultUiButton, DefaultUiButton];
        } else if (processedButtons.length === 1) {
          processedButtons.push(DefaultUiButton);
        }

        processedMessage = {
          //...loadedMessage,
          messageLabel,
          images: processedMessageImages,
          texts: processedMessageTexts,
          buttons: processedButtons,
          deliveryFormats,
          users
        };
        // console.log(processedMessage);
        if (screen === 'CREATE') {
          return (
            !_.isEmpty(processedMessage) && <Create draftId={inputId} inputData={processedMessage} />
          );
        } else if (screen === 'EDIT') {
          return (
            !_.isEmpty(processedMessage) && <Create messageId={inputId} inputData={processedMessage} />
          );
        } else {
          return null;
        }
      }
      else {
        return null;
      }
    default:
      return (
        <Create {...props} />
      );
  }
};

export default compose(
  withRouter,
  withApollo,
)(CreateGQLWrapper);
