import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { ROUTES } from 'components/Routes';
import { AuthBouncer } from 'components/Amplify';
import SendCodeForm from './sendCode';
import ResetPasswordForm from './resetPassword';

const PasswordForgetPage = props => {
  const {
    history: { push:p = () => { }, location: { state: { screen, customer } = {} } = { state: {} } }
  } = props;

  const [email, setEmail] = useState(null);
  const clearEmail = () => setEmail(null);

  switch (screen) {
    case ROUTES.PASSWORD_FORGET.screens.RESET_PASSWORD.state.screen:
      return (
        <AuthBouncer unauth redirectPath="/">
          <ResetPasswordForm
            email={email}
            clearEmail={clearEmail}
          />
        </AuthBouncer>
      );
    default:
      return (
        <AuthBouncer unauth redirectPath="/">
          <SendCodeForm parentSetEmail={setEmail} />
        </AuthBouncer>
      );
  }
};

PasswordForgetPage.propTypes = {
  history: PropTypes.object
};

export default compose(withRouter)(PasswordForgetPage);
