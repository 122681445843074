import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { QUERY_SERVICE_PROVIDER_USER } from 'constants/GraphQLData';
import { compose } from 'recompose';
import Table from 'screens/Customers/table';
import Details from 'screens/Customers/details';
import { customerFields, defaultColumns } from 'constants/customerDataFields';

export const ServiceProviderUserDataContext = React.createContext();

const ServiceProviderUserData = props => {
  const {
    data,
    data: {
      serviceProviderUser: {
        userId,
        serviceProviderId,
        email, givenName,
        familyName,
        avatar,
        serviceProviderLogo,
        serviceProviderTimezone
      } = {}
    } = {},
    children = null
  } = props;

  return (
    <ServiceProviderUserDataContext.Provider
      value={{
        userId,
        serviceProviderId,
        email, givenName,
        familyName,
        avatar,
        serviceProviderLogo,
        serviceProviderTimezone
      }}
    >
      {children}
    </ServiceProviderUserDataContext.Provider>
  )
};

ServiceProviderUserData.propTypes = {
  data: PropTypes.object,
};

ServiceProviderUserData.defaultProps = {
  data: {}
};

export default compose(
  graphql(QUERY_SERVICE_PROVIDER_USER)
)(ServiceProviderUserData);
