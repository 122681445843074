import React, { useState } from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import MultiSelectBox from 'screens/Customers/multiSelectBox';
import { Paper } from '@material-ui/core';

// this table is temporary, we plan to replace it with a custom table
const Table = props => {
  const { customerFields, defaultColumns, data: unPickedData, goToDetails, deleteCustomer } = props;
  const [columns, setColumns] = useState(defaultColumns); // the columns that are displayed in the table
  const fields = columns.map(item => {
    return get(item, 'field');
  });
  const data = unPickedData.map(item => {
    return pick(item, fields);
  });

  return (
    <div style={{ maxWidth: '100%' }}>
      <Paper style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <MultiSelectBox allItems={customerFields} items={columns} setItems={setColumns} />
      </Paper>
      <MaterialTable
        columns={columns}
        data={data}
        title="Customers"
        options={{
          selection: true,
          filtering: true,
          search: false,
          exportButton: true
        }}
        onRowClick={(evt, row, togglePanel) => goToDetails(row.tableData.id)}
        components={{
          Search: props => (
            <div>
              Hello
            </div>
          ),
        }}
      />
    </div>
  );
};

Table.propTypes = {
  customerFields: PropTypes.arrayOf(PropTypes.object),
  defaultColumns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  goToDetails: PropTypes.func
};

Table.defaultProps = {
  customerFields: [],
  defaultColumns: [],
  data: [],
  goToDetails: ()=>{}
};

export default Table;
