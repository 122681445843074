import React, { useState, useContext } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from 'components/Routes';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import { Auth } from 'aws-amplify';
import styles from './styles';

const ResetPasswordForm = props => {
  const INITIAL_STATE = {
    code: '',
    newPassword: '',
    error: null
  };
  const { classes, email = null, clearEmail,
    history: { push = () => { }, location: { state: { screen, customer } = {} } = { state: {} } }
  } = props;
  // if no email, then something went wrong: redirect back
  if (!email) {
    push(ROUTES.PASSWORD_FORGET.pathname);
  }
  const [code, setCode] = useState(INITIAL_STATE.code);
  const [newPassword, setNewPassword] = useState(INITIAL_STATE.newPassword);
  const [error, setError] = useState(INITIAL_STATE.error);
  const formStates = {
    code: value => setCode(value),
    newPassword: value => setNewPassword(value)
  };
  const onChange = evt => {
    formStates[evt.target.name](evt.target.value);
  };
  const backToSendCode = () => {
    clearEmail();
    const redirectPath = ROUTES.PASSWORD_FORGET.screens.SEND_CODE;
    push(redirectPath.pathname, redirectPath.state);
  };
  const onSubmit = event => {
    event.preventDefault();
    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => {
        push(ROUTES.PASSWORD_FORGET.pathname);
      })
      .catch(err => {
        setError(err);
      });
  };
  const isInvalid = code === '' || newPassword === '';

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="code">Verification Code</InputLabel>
            <Input id="code" name="code" value={code} onChange={onChange} autoFocus />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="newPassword">New Password</InputLabel>
            <Input
              id="newPassword"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={onChange}
              autoFocus
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            disabled={isInvalid}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
        </form>
        {error && (
          <Grid container justify="center">
            <Grid item xs={12}>
              {error.message}
            </Grid>
          </Grid>
        )}
      </div>
      <button onClick={backToSendCode}>Send a new code</button>
    </Container>
  );
};
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ResetPasswordForm);
