import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { sum, every } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  Popper,
  Typography,
  Paper,
  Fade,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Box,
  Modal
} from '@material-ui/core';
import PropertyDetailsMap from 'screens/Customers/DetailsOfProperties/propertyDetailsMap';
import MapIcon from '@material-ui/icons/Map';
import DeviceData from 'screens/Customers/DetailsOfProperties/deviceData';
import { propertyFields, deviceFields } from 'constants/customerDataFields';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  flex1: {
    flex: 1
  },
  mapIcon: {
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    padding: 2,
    borderRadius: 6,
    width: 30,
    height: 30,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.highLight
    }
  },
  selected: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.highLight
  }
}));

// This component displays details of one property associated with a user
// it has:
// -- a table with property info
// -- a table that expands to a detail sub-view for all devices
// -- a map
// child of Properties
// children:
// -- PropertyDetailsMap
// -- DeviceData
// inputs:
// -- property (one of the properties in the array)
// -- const propertyFields
const PropertyDetails = props => {
  const { property, property: { devices, members, latlon } = {} } = props;
  const classes = useStyles();
  const [detailsDevice, setDetailsDevice] = useState(null);
  const [showMap, setShowMap] = useState(false);

  const handleClose = () => {
    setDetailsDevice(null);
  };

  const showDeviceDetails = item => {
    setDetailsDevice(item);
  };

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  // true if there are any devices, (for conditional rendering)
  const hasDevices = sum(Object.values(devices).filter(item => item !== 'HasDevices')) > 0;

  return (
    <div>
      <div className={classes.flex}>
        <CustomHeading>Property Details</CustomHeading>
        <div>
          <MapIcon
            className={!showMap ? classes.mapIcon : classNames(classes.mapIcon, classes.selected)}
            onClick={toggleMap}
          />
        </div>
      </div>
      {showMap ? <PropertyDetailsMap latlon={latlon} /> : <div />}
      <ul className={classes.ul}>
        {property &&
          propertyFields.map((item, index) => {
            const fieldData = property[item.field] && property[item.field].toString();
            return (
              fieldData && <CustomDataItem key={item.field} title={item.title} data={fieldData} />
            );
          })}
        <CustomDataItem title="Region" data="" />
        <CustomDataItem title="Active Alerts" data="" />
      </ul>
      <CustomHeading>Associated Users</CustomHeading>
      <ul className={classes.ul}>
        {members &&
          members.map((item, index) => {
            const { role, userId } = item;
            // query customer name from customer user id
            return <CustomDataItem key={userId} title={userId} data={role} />;
          })}
      </ul>
      {hasDevices ? (
        <div>
          <CustomHeading>Devices</CustomHeading>
          <ul className={classes.ul}>
            {devices &&
              deviceFields.map((item, index) => {
                const fieldData = devices[item.field].toString();
                if (fieldData !== '0')
                  return (
                    fieldData && (
                      <CustomDataItem
                        key={item.field}
                        onClick={() => showDeviceDetails(item)}
                        title={item.title}
                        data={fieldData}
                      />
                    )
                  );
              })}
          </ul>
        </div>
      ) : (
        <CustomHeading>There are no devices associated with this property.</CustomHeading>
      )}
      <Modal open={!!detailsDevice} onClose={handleClose}>
        <DeviceData data={{ hello: 'hello' }} />
      </Modal>
    </div>
  );
};

PropertyDetails.propTypes = {
  property: PropTypes.object
};

PropertyDetails.defaultProps = {
  property: undefined
};

export default PropertyDetails;
