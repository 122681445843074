import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { AuthBouncer } from 'components/Amplify';
import { ROUTES } from 'components/Routes';
import styles from './styles';
import { Auth } from 'aws-amplify';
import settings from 'constants/settings'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
const { signupEnabled = false } = settings;

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  displayName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null
};

// the first AuthBouncer redirects users who are signed in
// the second AuthBouncer redirects users if signup is not allowed for this app
const SignUpPage = () => {
  return (
    <AuthBouncer unauth redirectPath='/'>
      <AuthBouncer condition={signupEnabled} redirectPath='/'>
        <SignUpForm />
      </AuthBouncer>
    </AuthBouncer>
  );
}

class SignUpFormBase extends Component {
  state = { ...INITIAL_STATE, loading:false };

  constructor(props) {
    super(props);
  }

  onSubmit = async event => {
    const { username, password } = this.state;
    this.setState({loading:true});
    Auth.signUp({
      username: username,
      password: password,
    })
    .then(data => {
      this.props.history.push(ROUTES['HOME'].pathname);
    })
    .catch(error => {
      this.setState({ error, loading:false });
    });
    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      username,
      password,
      error,
      loading
    } = this.state;

    const { classes } = this.props;

    const isInvalid =
      username === '' ||
      password === '';

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {loading ? 'Signing up...' : 'Sign up'}
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit} noValidate>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Email Address</InputLabel>
              <Input id="username" name="username" value={username} onChange={this.onChange} autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input name="password" type="password" id="password" value={password} onChange={this.onChange} />
            </FormControl>
            <Box pt={3}></Box>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive inspiration, marketing promotions and updates via email."
            />
            <Button
              type="submit"
              disabled={isInvalid}
              fullWidth
              variant="contained"
              color="primary"
              onClick={()=>this.setState({loading:true})}
              className={classes.submit}
            >
              Sign Up
            </Button>
          </form>
            {error && 
              <Grid container justify="center">
                <Grid item xs={12}>
                  {error.message}
                </Grid>
              </Grid>
            }
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
        </div>
      </Container>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <NavLink to={ROUTES.SIGN_UP.pathname}>Sign Up</NavLink>
  </p>
);

const SignUpForm = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink, SignUpFormBase };