import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { ROUTES } from 'components/Routes';
import { AuthBouncer } from 'components/Amplify';
import { Auth } from 'aws-amplify';
import BreadcrumbBar from './breadcrumbBar'
import settings from 'constants/settings';

const drawerWidth = 240;
const mainBarHeight = 64;

const styles = theme => {
  return {
    root: {
      width: '100%'
    },
    appBar: {
      marginLeft: theme.spacing(7) + 1,
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      height: mainBarHeight,
      backgroundColor: theme.palette.default.light,
      zIndex: theme.zIndex.drawer - 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    appBarFull: {
      marginLeft: 0,
      width: '100%',
      height: mainBarHeight,
      backgroundColor: theme.palette.default.light,
      zIndex: theme.zIndex.drawer - 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36
    },
    grow: {
      flexGrow: 1
    },
    title: {
      color: theme.palette.default.contrastText,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  };
};

class PrimaryAppBar extends React.Component {
  state = {
    anchorEl: null,
    anchorUnAuthEl: null,
    mobileMoreAnchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleLogout = (evt) => {
    Auth.signOut()
      .then(() => {
        this.props.history.push(ROUTES.LANDING.pathname);
      })
      .catch(error => {
        console.log('signout error', error);
      });
    evt.preventDefault();
    this.handleMenuClose();
  };

  handleSignUp = () => {
    this.props.history.push(ROUTES.SIGN_UP.pathname);
    this.handleMenuClose();
  };

  handleSignIn = () => {
    this.props.history.push(ROUTES.SIGN_IN.pathname);
    this.handleMenuClose();
  };

  handleProfile = () => {
    this.props.history.push(ROUTES.PROFILE.pathname);
    this.handleMenuClose();
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, open = false, noIndent = false } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderAuthMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleProfile}>Profile</MenuItem>
        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>}
      </Menu>
    );

    const renderUnAuthMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleSignIn}>Sign In</MenuItem>
        <AuthBouncer condition={process.env.REACT_APP_USER_SIGNUP_ALLOWED === 'yes'}>
          <MenuItem onClick={this.handleSignUp}>Sign Up</MenuItem>
        </AuthBouncer>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="default">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={11} color="default">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    const renderUnAuthMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleSignIn}>
          {/* <IconButton color="inherit">
            <Badge badgeContent={11} color="default">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <p>Sign In</p>
        </MenuItem>
        <AuthBouncer condition={settings.signupEnabled}>
          <MenuItem onClick={this.handleSignUp}>
            {/* <IconButton color="inherit">
              <AccountCircle />
            </IconButton> */}
            <p>Sign Up</p>
          </MenuItem>
        </AuthBouncer>
      </Menu>
    );

    return (
      <React.Fragment>
        <AppBar
          position="fixed"
          className={
            noIndent
              ? classes.appBarFull
              : classNames(classes.appBar, {
                  [classes.appBarShift]: open
                })
          }
        >
          <Toolbar disableGutters={false}>
            <BreadcrumbBar />
            {/* <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              Tony&apos;s &gt; Dashboard
            </Typography> */}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* <AuthBouncer auth>
                <React.Fragment>
                  <IconButton color="inherit">
                    <Badge badgeContent={4} color="default">
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton color="inherit">
                    <Badge badgeContent={17} color="default">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </React.Fragment>
              </AuthBouncer> */}
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <AuthBouncer auth>
          {renderAuthMenu}
          {renderMobileMenu}
        </AuthBouncer>
        <AuthBouncer unauth>
          {renderUnAuthMenu}
          {renderUnAuthMobileMenu}
        </AuthBouncer>
      </React.Fragment>
    );
  }
}

PrimaryAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(PrimaryAppBar);
