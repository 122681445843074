import React, { useState, useContext } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { isNil, isEmpty, intersection } from 'lodash';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export const AuthContext = React.createContext();

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
  }
});

export const AmplifyAuth = props => {
  const { children } = props;
  const [user, setUser] = useState(localStorage.getItem('user'));
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [permissions, setPermissions] = useState(localStorage.getItem('permissions') || []);
  const [isListening, setIsListening] = useState(false);

  if (!isListening) {
    Hub.listen('auth', channel => {
      setIsListening(true);
      const { data, event } = channel.payload;
      switch (event) {
        case 'signIn':
          const { attributes } = data;
          localStorage.setItem('user', attributes.email);
          setUser(attributes.email);
          localStorage.setItem('userId', attributes.sub);
          setUserId(attributes.sub);
          localStorage.setItem('role', attributes['custom:role']);
          setRole(attributes['custom:role']);
          localStorage.setItem('permissions', attributes['custom:permissions']);
          setPermissions(attributes['custom:permissions']);
          break;
        case 'signOut':
          localStorage.clear();
          setUser(null);
          setRole(null);
          setPermissions([]);
          break;
        default:
        //
      }
    });
  }

  return (
    <div>
      <AuthContext.Provider
        value={{
          user,
          userId,
          role,
          permissions
        }}
      >
        {children}
      </AuthContext.Provider>
    </div>
  );
};

AmplifyAuth.propTypes = {
  children: PropTypes.node
};

AmplifyAuth.defaultProps = {
  children: null
};

export const AuthBouncer = props => {
  const {
    user: userCxt = null,
    role: roleCxt = null,
    permissions: permissionsCxt = []
  } = useContext(AuthContext);

  const { condition, auth, unauth, roles, permissions, redirectPath, children } = props;

  let open = false;

  if (!isNil(condition)) {
    if (condition) {
      open = true;
    }
  } else if (auth) {
    if (userCxt) {
      open = true;
    }
  } else if (unauth) {
    if (!userCxt) {
      open = true;
    }
  } else if (roles.length === 0 || !isEmpty(intersection([roleCxt], roles))) {
    open = true;
  } else if (!isEmpty(intersection(permissionsCxt, permissions))) {
    open = true;
  }

  if (open) {
    return <div>{children}</div>;
  }
  if (redirectPath) {
    return (
      <div>
        <Redirect to={redirectPath} />
      </div>
    );
  }
  return null;
};

AuthBouncer.propTypes = {
  condition: PropTypes.bool,
  auth: PropTypes.bool,
  unauth: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string),
  redirectPath: PropTypes.string,
  children: PropTypes.node
};

AuthBouncer.defaultProps = {
  condition: null,
  auth: false,
  unauth: false,
  roles: [],
  permissions: [],
  redirectPath: null,
  children: null
};
