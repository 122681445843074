import React, { useState } from 'react';
import MaterialTable from 'material-table';
import Switch from '@material-ui/core/Switch';
import { Modal, InputLabel, Input, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { graphql } from 'react-apollo';
import { QUERY_SERVICE_PROVIDER_CUSTOMERS } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Table from 'screens/Customers/table';
import Details from 'screens/Customers/details';
import { ROUTES } from 'components/Routes';
import { customerFields, defaultColumns } from 'constants/customerDataFields';
import ExpansionPanel from 'screens/MessageCenter/Create/expansionPanel';
import Banner from 'screens/MessageCenter/Create/banner';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';
import DeliveryFormats from 'screens/MessageCenter/Create/deliveryFormats'
import CharLimit from 'screens/MessageCenter/Create/charLimit'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20
  },
  upper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: 30,
  },
  flex1: {
    flex: 1
  },
  lower: {

  }
}));

const TopUI = props => {
  const { hasMobileNotification, hasBanner, hasMessage, label = '', handleSetLabel, maxLength=0, editMode = false } = props
  const classes = useStyles();
  
  const { length = 0 } = label;

  return (
    <div className={classes.root}>
      <CustomHeading>{ editMode ? 'Edit Message' : 'Create New Message' }</CustomHeading>
      <div style={{ height: 20 }}/>
      <div className={classes.upper} >
        <div className={classes.flex1}>
          <CustomHeading tiny>Label (not visible)</CustomHeading>
          <TextField
            // placeholder="Label (not visible)"
            value={label}
            onChange={handleSetLabel}
            style={{ width: 250 }} />
          <CharLimit length={length} maxLength={maxLength} width={250} />
        </div>
        <div className={classes.flex1}>
          <CustomHeading small>Delivery Modes</CustomHeading>
          <DeliveryFormats
            hasMobileNotification={hasMobileNotification}
            hasBanner={hasBanner}
            hasMessage={hasMessage}
          />
        </div>
      </div>
      <div className={classes.lower}>
        <CustomHeading medium>Design</CustomHeading>
        {!editMode && <span>
          Complete the required message fields and select the optional delivery 
          modes before continuing in the next sections.
        </span>}
      </div>
    </div>
  );
};

TopUI.defaultProps = {
  hasMessage: false,
  hasMobileNotification: false,
  hasBanner: false
};

TopUI.propTypes = {
  hasMobileNotification: PropTypes.bool,
  hasBanner: PropTypes.bool,
  hasMessage: PropTypes.bool
};

export default TopUI;