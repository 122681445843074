import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridGallery from 'components/Custom/lightboxGridGallery';
import classNames from 'classnames';
import _ from 'lodash';
import CustomButton from 'components/Custom/CustomButton';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 32
  },
  accessor: {
    display: 'flex'
  },
  imageNameText: {
    padding: '8px 0px 8px 0px',
    width: 450
  }
}));

// this is for removing the base url from the image name display text
const BASE_URL_CHARS = 36; //'http://alanaimages.s3.amazonaws.com/'
const MAX_OUTPUT_CHARS = 60;
const formatSelectedImageDisplayText = (inputText) => {
  if (!_.isNil(inputText) && inputText !== 'notGiven') {
    let outputText = null;
    const length = inputText.length;

    // this case shouldn't happen
    if (length - BASE_URL_CHARS <= 0) {
      return _.truncate(inputText, {
        'length': MAX_OUTPUT_CHARS,
      });
    }

    return _.truncate(inputText.slice(BASE_URL_CHARS - 1), {
      'length': MAX_OUTPUT_CHARS,
    });
  }
  return null;
  
};

const ImageSelect = props => {
  const { images = [], selectedImage = 'notGiven', setSelectedImage, text = 'Select Image' } = props
  const classes = useStyles();
  const [show, setShow] = useState(false);

  // although selectedIndices is an array, there's only going to be at most one selected index in this case
  // if there is no matching fullImage then selectedIndices = [-1]
  const [selectedIndices, setSelectedIndices] = useState([_.findIndex(images, element => { return element === selectedImage })]);
  const [selectedImageDisplayText, setSelectedImageDisplayText] = useState(() => formatSelectedImageDisplayText(selectedImage));

  const setSelected = index => {
    if (_.includes(selectedIndices, index)) { // unselect
      setSelectedIndices(_.reject(selectedIndices, item => item === index));
      setSelectedImage('notGiven');
      setSelectedImageDisplayText(null);
    } else { // select
      setSelectedIndices([index]);
      const image = images[index]
      setSelectedImage(image);
      setSelectedImageDisplayText(() => formatSelectedImageDisplayText(image));
    }
  }

  const close = evt => {
    // evt.preventDefault();
    // evt.stopPropagation();
    setShow(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.accessor}>
        <CustomButton 
          onClick={() => setShow(true)}
          text={text}
          dark
        />
        <div style={{ width: 10 }} />
        <span className={classes.imageNameText} >
          {selectedImageDisplayText}
        </span>
      </div>
      {show && (
        <GridGallery close={close} images={images} selectedIndices={selectedIndices} setSelected={setSelected}></GridGallery>
      )}
    </div>
  );
};

ImageSelect.propTypes = {};

ImageSelect.defaultProps = {};

export default ImageSelect;