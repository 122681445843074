import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const MultiSelectBox = props => {
  const { allItems, items, setItems } = props;
  const classes = useStyles();
  const handleChange = evt => {
    setItems(evt.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        value={items}
        onChange={handleChange}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={selected => 'Select Columns'}
        MenuProps={MenuProps}
      >
        {allItems.map(item => (
          <MenuItem key={item.field} value={item}>
            <Checkbox checked={items.indexOf(item) > -1} />
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultiSelectBox.propTypes = {
  allItems: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object),
  setItems: PropTypes.func
};

MultiSelectBox.defaultProps = {
  allItems: [],
  items: [],
  setItems: () => {}
};

export default MultiSelectBox;
