import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import LocationIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles(theme => ({
  map: {
    margin: '0 auto',
    width: '40vw',
    height: '40vw',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '70vw',
      height: '70vw'
    }
  },
  marker: {
    color: '#DD4B3E',
    transform: 'translate(-50%, -100%)'
  }
}));

// the map displaying the location of one property in the customer details screen
// child of Customers/details
// inputs:
//   -- latlon
const PropertyDetailsMap = props => {
  const { latlon: { lat, lon } } = props;
  const classes = useStyles();

  // if location data isn't provided, don't render the map
  if (!lat || !lon) {
    return <div />;
  }

  return (
    <div className={classes.map}>
      <GoogleMapReact
        // bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={{ lat, lng: lon }}
        defaultZoom={11}
      >
        <LocationIcon className={classes.marker} lat={lat} lng={lon}  />
      </GoogleMapReact>
    </div>
  );
};

PropertyDetailsMap.propTypes = {
  latlon: PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number })
};

PropertyDetailsMap.defaultProps = {
  latlon: {lat: undefined, lon: undefined}
};

export default PropertyDetailsMap;
