import React, { useState, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { compose } from 'recompose';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider, MuiThemeProvider, createMuiTheme } from '@material-ui/styles';
import theme from 'constants/theme';
import PrimaryAppBar from 'components/PrimaryAppBar';
import SideBar from 'components/SideBar';
import { Routes } from 'components/Routes';
import { AuthBouncer, AuthContext } from 'components/Amplify';
import Apollo from 'components/Apollo';
// import theme from 'assets/theme.js';
import settings from 'constants/settings';
import { isNil } from 'lodash';

const { showSideBarWhenSignedOut = false } = settings;

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.grey.light
  },
});

const Layout = props => {
  const { classes } = props;
  const [drawer, setDrawer] = useState(false);
  const { user } = useContext(AuthContext);
  const showSidebar = showSideBarWhenSignedOut || !isNil(user);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrimaryAppBar noIndent={!showSidebar} open={drawer} setOpen={() => setDrawer(!drawer)} />
      {showSidebar && <SideBar open={drawer} setOpen={() => setDrawer(!drawer)} />}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  );
};
export default withStyles(styles, { withTheme: true })(Layout);
