import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { ROUTES } from 'components/Routes';
import styles from './styles';
import { Auth, I18n } from 'aws-amplify';
import { AuthBouncer } from 'components/Amplify';
import settings from 'constants/settings'
import ChangeTempPw from 'screens/SignIn/ChangeTempPw';
import _ from 'lodash';
const { signupEnabled = false } = settings;

//redirects users who are signed in
const SignInPage = () => (
  <AuthBouncer unauth redirectPath='/'>
    <SignInForm />
  </AuthBouncer>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, loading:false };
  }

  onSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;

    Auth.signIn(email, password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({ user }); // setting the user causes rendering of the ChangeTempPw component
        } else {
          this.props.history.push(ROUTES['HOME'].pathname);
        }
      })
      .catch(error => {
        this.setState({ error });
        this.setState({ loading: false, password: '' })
      });
    };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error, loading } = this.state;
    const { classes } = this.props;

    const isInvalid = password === '' || email === '';

    const mustChangeTempPw = !_.isNil(this.state.user); // signIn identified that a password change id required
    if (mustChangeTempPw) {
      return(
        <ChangeTempPw user={this.state.user} email={this.state.email} />
      ) 
    } else {
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {I18n.get('Sign In')}
            </Typography>
            <form className={classes.form} onSubmit={this.onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input id="email" name="email" value={email} onChange={this.onChange} autoFocus />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" value={password} onChange={this.onChange} />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.setState({ loading: true })}
                className={classes.submit}
                disabled={isInvalid}
              >
                {loading ?
                  <CircularProgress className={classes.progress} />
                  :
                  'Log in'
                }
              </Button>
            </form>
            {error &&
              <Grid container justify="center">
                <Grid item xs={12}>
                  {error.message}
                </Grid>
              </Grid>
            }
            <Grid container justify="flex-end">
              <AuthBouncer condition={signupEnabled}>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    Need an account? Sign up
                  </Link>
                </Grid>
              </AuthBouncer>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/forgot" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      )
    }
  }
}

const SignInForm = compose(
  withStyles(styles),
  withRouter,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };