import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, MuiThemeProvider, createMuiTheme } from '@material-ui/styles';
import theme from 'constants/theme';
import { AmplifyAuth } from 'components/Amplify';
import Apollo from 'components/Apollo';
import ServiceProviderUserData from 'components/ServiceProviderUserData';
//import theme from 'assets/theme.js';
import Layout from './layout';

const App = () => {
  return (
    // <MuiThemeProvider theme={createMuiTheme(theme)}>
    <Router>
      <AmplifyAuth>
        <Apollo>
          <ServiceProviderUserData>
            <ThemeProvider theme={theme}>
              <Layout />
            </ThemeProvider>
          </ServiceProviderUserData>
        </Apollo>
      </AmplifyAuth>
    </Router>
    // </MuiThemeProvider>
  );
};
export default App;
