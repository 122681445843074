import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  MUTATION_UPDATE_DRAFT,
  MUTATION_CREATE_MESSAGE,
  MUTATION_EDIT_MESSAGE,
} from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { ROUTES } from 'components/Routes';
import { compose } from 'recompose';
import ExpansionPanel from 'screens/MessageCenter/Create/expansionPanel';
import Notification from 'screens/MessageCenter/Create/notification';
import Banner from 'screens/MessageCenter/Create/banner';
import Message from 'screens/MessageCenter/Create/message';
import { CustomPaper, CustomHeading } from 'components/Custom/customStyledComponents';
import TopUI from 'screens/MessageCenter/Create/topUI';
import BottomUI from 'screens/MessageCenter/Create/bottomUI';
import { withApollo } from 'react-apollo';
import ModalDialog from 'components/Custom/modalDialog';
import { modalDialogTitle, ModalDialogContent } from 'screens/MessageCenter/Create/modalDialogContent'
import { updateTable } from 'screens/MessageCenter/Create/updateApolloCache'
import { DefaultUiButton, defaultDeliveryFormats, defaultUiImages, defaultUiTexts, makeDefaultLabel } from 'constants/messageUiDefaults';

const characterLimits = {
  label: 64,
  full: 512,
  notification: 32,
  subject: 30,
  summary: 128,
  button: 12
};

const useStyles = makeStyles(theme => ({
  paper: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

// this component is the root of the page where a person creates a message.
// it manages the message state, and the graphQL mutations for sending a message and saving a draft
const Create = props => {
  const classes = useStyles();

  const {
    history: { push, location: { state: { screen='NEW' } = {} } = {} } = {},
    inputData = {},
    draftId: inputDraftId = null,
    messageId: inputMessageId = null,
  } = props;

  let {
    messageLabel: initialLable,
    deliveryFormats: initialFormats,
    texts: initialTexts,
    images: initialImages,
    buttons: initialButtons,
    users: initialUsers,
    timeStart: initialTimeStart,
    timeEnd: initialTimeEnd,
  } = inputData;
  if (_.isNil(initialLable)) {
    initialLable = makeDefaultLabel();
  }
  if (_.isEmpty(initialFormats)) {
    initialFormats = defaultDeliveryFormats;
  }
  if (_.isEmpty(initialTexts)) {
    initialTexts = defaultUiTexts;
  }
  if (_.isEmpty(initialImages)) {
    initialImages = defaultUiImages;
  }
  if (_.isEmpty(initialButtons)) {
    initialButtons = [DefaultUiButton, DefaultUiButton];
  }
  if (_.isEmpty(initialUsers)) {
    initialUsers = [];
  }

  // console.log(inputData);

  // if edit mode is true then we are editing a message: different features of the ui will be shown/hidden
  const editMode = screen === 'EDIT';
  const notificationDisabled = editMode && !initialFormats.osNotification
  const bannerDisabled = editMode && !initialFormats.banner
  // const messageDisabled = editMode && !initialFormats.message

  const [label, setLabel] = useState(initialLable);
  const [deliveryFormats, setDeliveryFormats] = useState(initialFormats);
  const [buttons, setButtons] = useState(initialButtons);
  const [draftId, setDraftId] = useState(inputDraftId);
  const [messageId, setMessageId] = useState(inputMessageId);
  const [images, setImages] = useState(initialImages);
  const [texts, setTexts] = useState(initialTexts);
  const [users, setUsers] = useState(initialUsers);
  const [timeStart, setTimeStart] = useState(initialTimeStart);
  const [timeEnd, setTimeEnd] = useState(initialTimeEnd);
  const [modalDialogIsOpen, setModalDialogIsOpen] = useState(false);
  const [modalDialogState, setModalDialogState] = useState('CLOSED');
  const [redirectPath, setRedirectPath] = useState(undefined);
  const [redirectStateId, setRedirectStateId] = useState(undefined);
  const [lastAction, setLastAction] = useState('NONE'); // 'NONE', 'SAVE_DRAFT', 'SCHEDULE_MESSAGE', 'SEND_MESSAGE'

  const closeModal = () => {
    setLastAction('NONE');
    setModalDialogIsOpen(false);
    if (redirectPath && redirectStateId) {
      push(redirectPath.pathname, { ...redirectPath.state, stateId: redirectStateId });
    } else if (redirectPath) {
      push(redirectPath.pathname, redirectPath.state);
    }
  };

  const openModal = (state, path, id) => {
    setModalDialogState(state);
    setModalDialogIsOpen(true);
    if (path) {
      setRedirectPath(path);
    }
    if (id) {
      setRedirectStateId(id)
    }
  };

  const handleFormatChange = evt => {
    setDeliveryFormats({ ...deliveryFormats, [evt.target.value]: evt.target.checked });
  };
  const handleSetLabel = evt => {
    let newText = evt.target.value;
    if (newText.length <= characterLimits.label) {
      setLabel(newText);
    }
  };
  const setTitle = evt => {
    let newText = evt.target.value;
    if (newText && newText.length <= characterLimits.subject) {
      setTexts({ ...texts, subjectText: newText });
    }
  };
  const setNotificationText = evt => {
    let newText = evt.target.value;
    if (newText && newText.length <= characterLimits.notification) {
      setTexts({ ...texts, notificationText: newText });
    }
  };
  const setBannerText = evt => {
    let newText = evt.target.value;
    if (newText && newText.length <= characterLimits.summary) {
      setTexts({ ...texts, summaryText: newText });
    }
  };
  const setBannerImage = image => {
    setImages({ ...images, bannerImage: image });
  };
  const setFullText = markdown => {
    setTexts({ ...texts, fullText: markdown });
  };
  const setFullImage = image => {
    setImages({ ...images, fullImage: image });
  };
  const setButton = (button, number) => {
    console.log(button);
    if (number === 1) {
      setButtons([button, buttons[1]]);
    };
    if (number === 2) {
      setButtons([buttons[0], button]);
    };
  };

  const [
    createDraftMutation,
    {
      loading: createDraftLoading,
      error: createDraftError,
      data: createDraftData
    }
  ] = useMutation(MUTATION_UPDATE_DRAFT, { update: (cache, reply) => updateTable(cache, reply, 'NEW_DRAFT') });
  const [
    updateDraftMutation,
    {
      loading: updateDraftLoading,
      error: updateDraftError,
      data: updateDraftData
    }
  ] = useMutation(MUTATION_UPDATE_DRAFT, { update: (cache, reply) => updateTable(cache, reply, 'EDIT_DRAFT') });
  const [
    scheduleMessageMutation,
    {
      loading: scheduleMessageLoading,
      error: scheduleMessageError,
      data: scheduleMessageData
    }
  ] = useMutation(MUTATION_CREATE_MESSAGE, { update: (cache, reply) => updateTable(cache, reply, 'NEW_MESSAGE') });
  const [
    sendMessageMutation,
    {
      loading: sendMessageLoading,
      error: sendMessageError,
      data: sendMessageData
    }
  ] = useMutation(MUTATION_CREATE_MESSAGE, { update: (cache, reply) => updateTable(cache, reply, 'NEW_MESSAGE') });
  const [
    editMessageMutation,
    {
      loading: editMessageLoading,
      error: editMessageError,
      data: editMessageData
    }
  ] = useMutation(MUTATION_EDIT_MESSAGE, { update: (cache, reply) => updateTable(cache, reply, 'EDIT_MESSAGE') });

  /*
  Actions:
  'NONE'
  'SAVE_DRAFT'
  'SCHEDULE_MESSAGE'
  'SEND_MESSAGE'
  'EDIT_MESSAGE'
  'LIVE_PREVIEW'

  Modal States:
  'CLOSED'
  'CONFIRM_EXIT'
  'ERROR_SAVING_DRAFT'
  'ERROR_SCHEDULING_MESSAGE'
  'ERROR_SENDING_MESSAGE'
  'ERROR_EDITING_MESSAGE'
  'ERROR_SENDING_LIVE_PREVIEW'
  'HAS_SAVED_DRAFT'
  'SCHEDULE_MESSAGE'
  'HAS_SCHEDULED_MESSAGE'
  'SEND_MESSAGE'
  'HAS_SENT_MESSAGE'
  'HAS_EDITED_MESSAGE'
  'HAS_SENT_LIVE_PREVIEW'
  */
  
  useEffect(() => {
    switch (lastAction) {
      case 'NONE':
        break;
      case 'SAVE_DRAFT':
        if (createDraftError || updateDraftError) {
          // console.log(updateDraftError);
          openModal('ERROR_SAVING_DRAFT');
        } else if (createDraftData) {
          // put in the draftId 
          let { updateDraft: { draftId: newId = null } = {} } = createDraftData;
          setDraftId(newId);
          openModal('HAS_SAVED_DRAFT');
        } else if (updateDraftData) {
          openModal('HAS_SAVED_DRAFT');
        }
        break;
      case 'SCHEDULE_MESSAGE':
        if (scheduleMessageError) {
          // console.log(scheduleMessageError);
          openModal('ERROR_SCHEDULING_MESSAGE');
        } else if (scheduleMessageData) {
          // put in the messageId for redirect 
          let { createMessage: { messageId: newId = null } = {} } = scheduleMessageData;
          setMessageId(newId);
          openModal('HAS_SCHEDULED_MESSAGE', ROUTES.MESSAGE_CENTER.screens.EDIT, newId);
        }
        break;
      case 'SEND_MESSAGE':
        if (sendMessageError) {
          // console.log(sendMessageError);
          openModal('ERROR_SENDING_MESSAGE');
        } else if (sendMessageData) {
          // put in the messageId for redirect 
          let { createMessage: { messageId: newId = null } = {} } = sendMessageData;
          setMessageId(newId);
          openModal('HAS_SENT_MESSAGE', ROUTES.MESSAGE_CENTER.screens.EDIT, newId);
        }
        break;
      case 'EDIT_MESSAGE':
        if (editMessageError) {
          // console.log(editMessageError);
          openModal('ERROR_EDITING_MESSAGE');
        } else if (editMessageData) {
          openModal('HAS_EDITED_MESSAGE');
        }
        break;
      case 'LIVE_PREVIEW':
        if (false) { // error
          openModal('ERROR_SENDING_LIVE_PREVIEW');
        } else if (true) { // success
          openModal('HAS_SENT_LIVE_PREVIEW');
        }
        break;
      default:
        break;
    }
  },
    [lastAction, createDraftError, updateDraftError, createDraftData, updateDraftData, scheduleMessageError, scheduleMessageData, sendMessageError, sendMessageData, editMessageError, editMessageData]
  );

  const saveDraft = () => {
    const newDraft = {
      messageLabel: label,
      messageType: 'message',
      deliveryFormats,
      images,
      texts,
      buttons,
      users,
      draftId,
    };
    // console.log(newDraft);
    // the onCompleted callback currently doesn't work otherwise I would use it.
    // instead, I'm keeping track of the last action and opening the modals accordingly
    // (in useEffect above)
    if (!_.isNil(draftId)) {
      updateDraftMutation({
        variables: { draft: newDraft },
        // onCompleted: data => {
        //   console.log('completed')
        //   openModal('HAS_SAVED_DRAFT');
        // },
        // onError: error => {
        //   console.log('error')
        //   openModal('ERROR_SAVING_DRAFT');
        // }
      });
    } else {
      createDraftMutation({
        variables: { draft: newDraft },
      });
    }
    
    setLastAction('SAVE_DRAFT');
  };

  const scheduleMessage = (start) => {
    const newMessage = {
      messageLabel: label,
      messageType: 'message',
      deliveryFormats,
      images,
      texts,
      buttons,
      users,
      timeStart: start,
    };
    // console.log(newMessage);
    scheduleMessageMutation({
      variables: { message: newMessage }
    });
    setLastAction('SCHEDULE_MESSAGE');
  };

  const sendMessage = () => {
    const newMessage = {
      messageLabel: label,
      messageType: 'message',
      deliveryFormats,
      images,
      texts,
      buttons,
      users,
      timeStart: Date.now(),
    };
    // console.log(newMessage);
    sendMessageMutation({
      variables: { message: newMessage }
    });
    setLastAction('SEND_MESSAGE');
  };

  const editMessage = () => {
    const editedMessage = {
      // messageLabel: label,
      images,
      texts,
      buttons,
      messageId
    };
    // console.log(editedMessage);
    editMessageMutation({
      variables: { message: editedMessage }
    });
    setLastAction('EDIT_MESSAGE');
  };

  const sendLivePrivew = () => {
    const newMessage = {
      messageLabel: label,
      messageType: 'message',
      deliveryFormats,
      images,
      texts,
      buttons,
      users,
      timeStart: Date.now(),
    };
    // console.log(newMessage);
    // sendMessageMutation({
    //   variables: { message: newMessage }
    // });
    setLastAction('LIVE_PREVIEW');
  };

  const handleButtonClick = buttonIdentifier => {
    switch (buttonIdentifier) {
      case 'cancel':
        openModal('CONFIRM_EXIT');
        break;
      case 'save':
        saveDraft();
        break;
      case 'schedule':
        openModal('SCHEDULE_MESSAGE');
        break;
      case 'send':
        openModal('SEND_MESSAGE');
        break;
      case 'edit':
        editMessage();
        break;
      case 'preview':
        sendLivePrivew();
        break;
      default:
      //
    }
  };
  return (
    <div>
      <ModalDialog
        title={modalDialogTitle(modalDialogState)}
        isOpen={modalDialogIsOpen}
        close={closeModal}
      >
        <ModalDialogContent
          state={modalDialogState}
          scheduleMessage={scheduleMessage}
          sendMessage={sendMessage}
          hasMobileNotification={deliveryFormats.osNotification}
          hasBanner={deliveryFormats.banner}
          // hasMessage={deliveryFormats.messageBody}
          close={closeModal}
          allUsers={_.isEmpty(users)}
        />
      </ModalDialog>
      <CustomPaper className={classes.paper}>
        <TopUI
          editMode={editMode}
          hasMobileNotification={deliveryFormats.osNotification}
          hasBanner={deliveryFormats.banner}
          // hasMessage={deliveryFormats.messageBody}
          label={label}
          handleSetLabel={handleSetLabel}
          maxLength={characterLimits.label}
        />
        <div>
          <ExpansionPanel
            // alwaysClosed={messageDisabled}
            title="Message"
          >
            <Message
              title={texts.subjectText}
              setTitle={setTitle}
              titleMaxLength={characterLimits.subject}
              fullText={texts.fullText}
              setFullText={setFullText}
              fullImage={images.fullImage}
              setFullImage={setFullImage}
              maxLength={characterLimits.full}
              maxButtonLabelLength={characterLimits.button}
              buttons={buttons}
              setButton={setButton}
            />
          </ExpansionPanel>
            
          <ExpansionPanel
            title="Mobile Notification"
            alwaysClosed={notificationDisabled || !deliveryFormats.osNotification}
            pushOpen={deliveryFormats.osNotification}
            control={
              <Switch
                checked={deliveryFormats.osNotification}
                value="osNotification"
                onChange={handleFormatChange}
                disabled={editMode}
              />
              }
          >
            <Notification
              title={texts.subjectText}
              notificationText={texts.notificationText}
              setNotificationText={setNotificationText}
              maxLength={characterLimits.notification}
            />
          </ExpansionPanel>
          <ExpansionPanel
            title="Banner"
            alwaysClosed={bannerDisabled || !deliveryFormats.banner}
            pushOpen={deliveryFormats.banner}
            control={
              <Switch
                checked={deliveryFormats.banner}
                value="banner"
                onChange={handleFormatChange}
                disabled={editMode}
              />
            }
          >
            <Banner
              title={texts.subjectText}
              bannerText={texts.summaryText}
              setBannerText={setBannerText}
              maxLength={characterLimits.summary}
              bannerImage={images.bannerImage}
              setBannerImage={setBannerImage}
            />
          </ExpansionPanel>
        </div>
        <BottomUI
          editMode={editMode}
          handleButtonClick={handleButtonClick}
          users={users}
          setUsers={setUsers}
          timeStart={timeStart}
          timeEnd={timeEnd}
          setTimeStart={setTimeStart}
          setTimeEnd={setTimeEnd}
        />
      </CustomPaper>
    </div>
  );
};

Create.propTypes = {};

Create.defaultProps = {};

export default compose(
  withRouter,
  withApollo
)(Create);
