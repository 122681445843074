import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { Modal, Input, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { graphql } from 'react-apollo';
import { QUERY_SERVICE_PROVIDER_CUSTOMERS } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Table from 'screens/Customers/table';
import Details from 'screens/Customers/details';
import { ROUTES } from 'components/Routes';
import { customerFields, defaultColumns } from 'constants/customerDataFields';
import ExpansionPanel from 'screens/MessageCenter/Create/expansionPanel';
import NotificationPreview from 'screens/MessageCenter/Create/notificationPreview';
// import FlexFrame from 'screens/MessageCenter/Create/flexFrame';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';
import TextEditor from 'components/Custom/TextEditor';
import CharLimit from 'screens/MessageCenter/Create/charLimit'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    // padding: '20px 60px',
    padding: '20px 0px',
    margin: 'auto',
    width: '80%',
  },
  inputs: {

  },
  preview: {
    width: 300,
  },
  'inputs, preview': {

  },
}));

const Notification = props => {
  const { title = '', notificationText='', setNotificationText, maxLength = 0, disabled = false } = props
  const classes = useStyles();

  const { length = 0 } = notificationText;

  return (
    <div className={classes.root}>
      <div className={classes.inputs}>
        <div style={{ marginBottom: 30 }}>Title: {title}</div>
        <TextField disabled={disabled} placeholder="Teaser Text" value={notificationText} onChange={setNotificationText} />
        <CharLimit length={length} maxLength={maxLength} width={182} />
      </div>
      <div className={classes.preview}>
        <NotificationPreview />
      </div>
    </div>
  );
};

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
