import gql from 'graphql-tag';

const QUERY_IMAGES = gql`
  query images {
    images {
      baseURL
      genericImages {
        banner
        fullscreen
        logos
      }
      serviceProviderImages {
        banner
        fullscreen
        logos
      }
    }
  }
`;
export default QUERY_IMAGES;

export const QUERY_BANNER_IMAGES = gql`
  query {
    images {
      baseURL
      genericImages {
        banner
      }
      serviceProviderImages {
        banner
      }
    }
  }
`;

export const QUERY_FULLSCREEN_IMAGES = gql`
  query {
    images {
      baseURL
      genericImages {
        fullscreen
      }
      serviceProviderImages {
        fullscreen
      }
    }
  }
`;