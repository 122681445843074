import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import {
  Popper,
  Typography,
  Paper,
  Fade,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Box,
  Radio
} from '@material-ui/core';
import PropertyDetailsMap from 'screens/Customers/DetailsOfProperties/propertyDetailsMap';
import RadioSelectGroup from 'screens/Customers/DetailsOfProperties/radioSelectGroup';
import PropertyDetails from 'screens/Customers/DetailsOfProperties/propertyDetails';
import { propertyFields } from 'constants/customerDataFields';
import {
  CustomPaper,
  CustomHeading,
  CustomDataItem,
  CustomClickableDataItem,
  CustomRadioSelectItem
} from 'components/Custom/customStyledComponents';

const useStyles = makeStyles(theme => ({}));

// This component displays all properties associated with a user and a radio-togle to select one
// it has:
// -- a radio group to toggle between properties
// child of details
// children:
// -- PropertyDetails
// inputs:
// -- property (the array)
// -- const propertyFields

const Properties = props => {
  const { property } = props;
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <CustomPaper narrow>
      <CustomHeading>Properties</CustomHeading>
      <RadioSelectGroup
        titles={property}
        field="address"
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      <PropertyDetails property={property[selectedIndex]} />
    </CustomPaper>
  );
};

Properties.propTypes = {
  property: PropTypes.object
};

Properties.defaultProps = {
  property: undefined
};

export default Properties;
