import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql } from 'react-apollo';
import { QUERY_MESSAGES_DRAFTS_FOR_TABLE } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Table from 'screens/MessageCenter/table';
import { ROUTES } from 'components/Routes';
import { customerFields, defaultColumns } from 'constants/customerDataFields';
import CreateGQLWrapper from 'screens/MessageCenter/createGQLWrapper';

const messagesTableColumns = [
  { title: 'Label', field: 'label' },
  { title: 'Stage', field: 'stage' },
  // { title: 'Type', field: 'messageType' },
  { title: 'Title', field: 'subjectText' },
  { title: 'Date Created', field: 'createdAtDate' },
  // { title: 'Created By', field: 'createdBy' },
  // { title: 'Id', field: 'id' }
]

const MessageCenter = props => {
  const {
    data = {},
    history: {
      push,
      location: {
        state: {
          screen,
          stateId
        } = {}
      } = {}
    } = {}
  } = props

  // console.log(data);

  // process the graphQL query data so it is formatted for imput into the table displaying messages and drafts.
  // as an alternative, we may want to create an new graphQL resolver that gives exactly the data that the frontend table needs.
  // this resolver would query the messages table, the drafts table, and possibly the servivcProviderUsers table to get the user names.
  // am doing this in the frontend for now because we'll likely be changing the table, so we don't yet know how the data
  // will ultimately need to be formated
  const { messages = [], drafts = [] } = data;
  const messagesForTable = messages.map(item => {
    const {
      messageLabel = '',
      stage = '',
      messageId = '',
      messageType = '',
      createdAt = 0,
      createdBy = '',
      texts = null,
    } = item;
    let subjectText = '';
    if (_.isPlainObject(texts)) {
      ({ subjectText = '' } = texts);
    }
    const createDate = new Date(createdAt);
    return ({
      label: messageLabel,
      stage: stage,
      id: messageId,
      messageType,
      createdAtDate: createDate.toDateString(),
      createdBy,
      subjectText
    })
  })
  const draftsForTable = drafts.map(item => {
    const {
      messageLabel = '',
      draftId = '',
      messageType = '',
      createdAt = 0,
      createdBy = '',
      texts = null,
    } = item;
    let subjectText = '';
    if (_.isPlainObject(texts)) {
      ({ subjectText = '' } = texts);
    }
    const createDate = new Date(createdAt);
    return ({
      label: messageLabel,
      stage: 'draft',
      id: draftId,
      messageType,
      createdAtDate: createDate.toDateString(),
      createdBy,
      subjectText
    })
  })
  const tableData = [...messagesForTable, ...draftsForTable]

  const [selectedItem, setSelectedItem] = useState({});

  const goToItem = rowId => {
    // console.log(tableData[rowId]);
    setSelectedItem(tableData[rowId]);
    const { stage } = tableData[rowId]
    let redirectPath;
    switch (stage) {
      case 'draft':
        redirectPath = ROUTES.MESSAGE_CENTER.screens.CREATE;
        push(redirectPath.pathname, redirectPath.state);
        return;
      default:
        redirectPath = ROUTES.MESSAGE_CENTER.screens.EDIT;
        push(redirectPath.pathname, redirectPath.state);
        return;
    }
  }

  const deleteItem = rowId => {
    return;
  };

  const newMessage = () => {
    const redirectPath = ROUTES.MESSAGE_CENTER.screens.NEW;
    push(redirectPath.pathname, redirectPath.state);
  };
  let id;
  switch (screen) {
    case ROUTES.MESSAGE_CENTER.screens.NEW.state.screen:
      return <CreateGQLWrapper />
    case ROUTES.MESSAGE_CENTER.screens.CREATE.state.screen:
      if (!_.isNil(stateId)) {
        id = stateId
      } else {
        ({ id } = selectedItem);
      }
      return <CreateGQLWrapper inputId={ id } />
    case ROUTES.MESSAGE_CENTER.screens.EDIT.state.screen:
      if (!_.isNil(stateId)) {
        id = stateId
      } else {
        ({ id } = selectedItem);
      }
      return <CreateGQLWrapper inputId={id} />
    default:
      return (
        <Table
          newMessage={newMessage}
          fields={messagesTableColumns}
          defaultColumns={messagesTableColumns}
          tableData={tableData}
          goToItem={goToItem}
          deleteItem={deleteItem}
        />
      );
  }
};

MessageCenter.propTypes = {};

MessageCenter.defaultProps = {
};

export default compose(
  withRouter,
  graphql(QUERY_MESSAGES_DRAFTS_FOR_TABLE)
)(MessageCenter);
