import React, { useState, useContext } from 'react';
import _ from 'lodash';
import DeliveryFormats from 'screens/MessageCenter/Create/deliveryFormats';
import { TextField, Checkbox } from '@material-ui/core';
import CustomButton from 'components/Custom/CustomButton';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { ServiceProviderUserDataContext } from 'components/ServiceProviderUserData'

/*
Modal States:
'CLOSED'
'CONFIRM_EXIT'
'ERROR_SAVING_DRAFT'
'ERROR_SCHEDULING_MESSAGE'
'ERROR_SENDING_MESSAGE'
'ERROR_EDITING_MESSAGE'
'ERROR_SENDING_LIVE_PREVIEW'
'HAS_SAVED_DRAFT'
'SCHEDULE_MESSAGE'
'HAS_SCHEDULED_MESSAGE'
'SEND_MESSAGE'
'HAS_SENT_MESSAGE'
'HAS_EDITED_MESSAGE'
'HAS_SENT_LIVE_PREVIEW'
*/
export const modalDialogTitle = state => {
  switch (state) {
    case 'CLOSED':
      return '';
    case 'CONFIRM_EXIT':
      return 'Are you sure you want exit?';
    case 'ERROR_SAVING_DRAFT':
      return 'Error saving draft';
    case 'ERROR_SCHEDULING_MESSAGE':
      return 'Error scheduling message';
    case 'ERROR_SENDING_MESSAGE':
      return 'Error sending message';
    case 'ERROR_EDITING_MESSAGE':
      return 'Error editing message';
    case 'ERROR_SENDING_LIVE_PREVIEW':
      return 'Error sending preview';
    case 'HAS_SAVED_DRAFT':
      return 'Saved';
    case 'SCHEDULE_MESSAGE':
      return 'Schedule Release';
    case 'HAS_SCHEDULED_MESSAGE':
      return 'Scheduled';
    case 'SEND_MESSAGE':
      return 'Send Message';
    case 'HAS_SENT_MESSAGE':
      return 'Sent';
    case 'HAS_EDITED_MESSAGE':
      return 'Edited';
    case 'HAS_SENT_LIVE_PREVIEW':
      return 'Preview Sent';
    default:
      return '';
  }
};

export const ModalDialogContent = ({
  state,
  scheduleMessage,
  sendMessage,
  hasMobileNotification,
  hasBanner,
  hasMessage,
  close,
  allUsers = false }) => {

  // send is disabled when all of: 1.sending to all users, 2.has not checked to confirm
  const [sendDisabled, setSendDisabled] = useState(allUsers);

  const {
    serviceProviderTimezone = null
  } = useContext(ServiceProviderUserDataContext);

  const now = moment().tz(serviceProviderTimezone);
  const [date, setDate] = useState(now.format('YYYY-MM-DD'));
  const [time, setTime] = useState(now.format('HH:mm'));

  const handleDateChange = evt => {
    setDate(evt.target.value);
  };

  const handleTimeChange = evt => {
    setTime(evt.target.value);
  };

  const handleScheduleMessage = () => {
    const afterInput = moment().tz(`${date}T${time}`, serviceProviderTimezone);
    scheduleMessage(afterInput.valueOf());
  };

  const ERROR_TEXT = 'Please contact Alana support if this problem persists.';

  const DateTimeSelectComponent = () => (
    <div>
      <div><span>Select a date and a time in the future ({serviceProviderTimezone} time zone) for when you want to release your message:</span></div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: 10 }} />
        <TextField type="date" value={date} onChange={handleDateChange} />
        <div style={{ width: 10 }} />
        <TextField type="time" value={time} onChange={handleTimeChange} />
      </div>
    </div>
  );

  const DeliveryFormatsComponent = () => (
    <div>
      <div>
        <span>Your message will be delivered with delivery modes:</span>
      </div>
      <div>
        <b><DeliveryFormats hasMobileNotification={hasMobileNotification} hasBanner={hasBanner} hasMessage={hasMessage} /></b>
      </div>
    </div>
  );

  const ConfirmSendComponent = () => (
    <div>
      <div>
        <span>This message will be delivered to all users.  Click to confirm:</span>
        <span style={{ width: 10 }} />
        <Checkbox
          checked={!sendDisabled}
          onChange={(evt) => { setSendDisabled(!evt.target.checked) }}
        />
      </div>
      <div style={{ height: 10 }} />
    </div>
  );

  switch (state) {
    case 'CLOSED':
      return <div />;
    case 'CONFIRM_EXIT':
      return <div><span>Any unsaved changes will be lost if you leave this page.</span></div>;
    case 'ERROR_SAVING_DRAFT':
    case 'ERROR_SCHEDULING_MESSAGE':
    case 'ERROR_SENDING_MESSAGE':
    case 'ERROR_EDITING_MESSAGE':
    case 'ERROR_SENDING_LIVE_PREVIEW':
      return <div><span>{ERROR_TEXT}</span></div>;
    case 'HAS_SAVED_DRAFT':
      return <div><span>Your draft has been saved.</span></div>;
    case 'SCHEDULE_MESSAGE':
      return (
        <div>
          <DateTimeSelectComponent />
          <div style={{ height: 20 }} />
          <DeliveryFormatsComponent />
          <div style={{ height: 10 }} />
          {allUsers && <ConfirmSendComponent />}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomButton
              onClick={close}
              text="Close"
            />
            <CustomButton
              disabled={sendDisabled}
              onClick={handleScheduleMessage}
              text="Schedule"
              dark
            />
          </div>
        </div>
      );
    case 'HAS_SCHEDULED_MESSAGE':
      return (
        <div>
          <div><span>Your message has been scheduled.</span></div>
          <div><span></span></div>
        </div>
      );
    case 'SEND_MESSAGE':
      return (
        <div>
          <DeliveryFormatsComponent />
          <div style={{ height: 20 }} />
          {allUsers && <ConfirmSendComponent />}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomButton
              onClick={close}
              text="Close"
            />
            <CustomButton
              disabled={sendDisabled}
              onClick={sendMessage}
              text="Send Now"
              dark
            />
          </div>
        </div>
      );
    case 'HAS_SENT_MESSAGE':
      return <div><span>Your message has been sent.</span></div>;
    case 'HAS_EDITED_MESSAGE':
      return <div><span>Your message has been edited.</span></div>;
    case 'HAS_SENT_LIVE_PREVIEW':
      return <div><span>Log in to the app with your developer accout to view the preview.</span></div>;
    default:
      return <div />;
  }
};

ModalDialogContent.defaultProps = {
  hasMessage: true
};

ModalDialogContent.propTypes = {
  hasMobileNotification: PropTypes.bool.isRequired,
  hasBanner: PropTypes.bool.isRequired,
  hasMessage: PropTypes.bool
};