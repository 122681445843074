/* 
  info:

  I wanted to point out this React functionality to you so you can look at 
  nesting this into the dashboard.  My suggestion would be to have a global 
  and then more specific boundaries for each menu item.

  Error Boundaries are really useful from the UX side of things so that you 
  never show a react crash page to the user but something that’s more useful 
  for them to see there is a problem.  If you could put these into the dashboard 
  and then maybe ask for a UI that looks nice for “something bad happened” then 
  it will just make sure any missed error conditions crash nicely.

  We could actually link the error boundaries to our backend to post the error so
  we can track bugs too.  But that can be a second phase (I’d like to do that 
  with the app too). (Tony)

  https://reactjs.org/docs/error-boundaries.html

*/

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ROUTES } from 'components/Routes';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { alertText, redirectTo, redirectTo: { pathname, state }, history } = this.props;
    if (alertText) {
      alert(alertText);
    }
    if (redirectTo) {
      history.push(pathname, state);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
        </div>
      ) 
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  alertText: PropTypes.string,
  redirectTo: PropTypes.object, // { pathname, state }
  children: PropTypes.any,
  history: PropTypes.object
};

export default withRouter(ErrorBoundary);