import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { AuthBouncer } from 'components/Amplify';
import { Auth } from 'aws-amplify';

const INITIAL_STATE = {
  oldPassword: '',
  newPassword: '',
  error: null,
};

const PasswordChangePage = () => (
  //redirects users who are not signed in
  <AuthBouncer auth redirectPath='/'>
    <PasswordChangeForm />
  </AuthBouncer>
);

class PasswordChangeFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { oldPassword, newPassword } = this.state;

    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log(user)
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        console.log(error);
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { oldPassword, newPassword, error } = this.state;
    const { classes } = this.props;

    const isInvalid =
      false;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password 
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
              <Input id="oldPassword" name="oldPassword" type="password" value={oldPassword} onChange={this.onChange} autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="newPassword">New Password</InputLabel>
              <Input id="newPassword" name="newPassword" type="password" value={newPassword} onChange={this.onChange} autoFocus />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              disabled={isInvalid}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Change Password
            </Button>
          </form>
            {error && 
              <Grid container justify="center">
                <Grid item xs={12}>
                  {error.message}
                </Grid>
              </Grid>
            }
        </div>
      </Container>
    );
  }
}

export default PasswordChangePage;

const PasswordChangeForm = compose(
  withStyles(styles),
  withRouter
)(PasswordChangeFormBase);

export { PasswordChangeForm };
