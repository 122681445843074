// the column titles and fields for the customer data displayed in Customer table and details view
export const customerFields = [
  // { field: 'userId', title: 'User ID' },
  { field: 'email', title: 'Email' },
  { field: 'familyName', title: 'Family Name' },
  // { field: 'gender', title: 'Gender' },
  { field: 'givenName', title: 'Given Name' },
  { field: 'phoneNumber', title: 'Phone Number' },
  // { field: 'serviceProviderId', title: 'Service Provider ID' },
  // { field: 'timeOffset', title: 'Time Offset' },
  // { field: 'hasThermostats', title: 'Thermostats' },
  // { field: 'hasSmokeDetectors', title: 'Smoke Detectors' },
  // { field: 'hasContacts', title: 'Magnetic Contacts' },
  // { field: 'hasMotions', title: 'Motion Dectectors' },
  // { field: 'hasLights', title: 'Lights' },
  // { field: 'hasWaterDetectors', title: 'Water Detectors' },
  // { field: 'hasCameras', title: 'Cameras' }
  // ??? { field: 'notifications', title: 'Notifications' },
];

// the default customer table columns
export const defaultColumns = [customerFields[0], customerFields[1]];

// the devices that belong to a customer, for the customer details screen
export const deviceFields = [
  { field: 'hasThermostats', title: 'Thermostats' },
  { field: 'hasSmokeDetectors', title: 'Smoke Detectors' },
  { field: 'hasContacts', title: 'Magnetic Contacts' },
  { field: 'hasMotions', title: 'Motion Dectectors' },
  { field: 'hasLights', title: 'Lights' },
  { field: 'hasWaterDetectors', title: 'Water Detectors' },
  { field: 'hasCameras', title: 'Cameras' }
];

// the fields for the properties associated with a customer, for the details screen
export const propertyFields = [
  // { field: 'userId', title: 'User ID' },
  // { field: 'houseId', title: 'House ID' },
  { field: 'address', title: 'Address' },
  { field: 'city', title: 'City' },
  { field: 'postalCode', title: 'Postal Code' },
  { field: 'propertyType', title: 'Property Type' },
];