import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { ROUTES } from 'components/Routes';
import styles from './styles';
import { Auth, I18n } from 'aws-amplify';
import { AuthBouncer } from 'components/Amplify';
import _ from 'lodash';

const ChangeTempPw = props => {

  const {
    user,
    email,
    history,
    classes
  } = props;

  const [state, setState] = useState({ password: '', error: undefined, loading: false })

  const onSubmit = event => {
    event.preventDefault();

    Auth.completeNewPassword(user, state.password)
      .then(() => {
        Auth.signIn(email, state.password)
          .then(() => {
            history.push(ROUTES['HOME'].pathname);
          });
      })
      .catch(error => {
        setState({ password: '', error, loading: false })
      });
  };

  const onChange = event => {
    setState({...state, [event.target.name]: event.target.value });
  };

  const { password, error, loading } = state;
  const isInvalid = password === '';
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {I18n.get('Please Change Your Temporary Password')}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password" type="password" id="password" value={password} onChange={onChange} />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setState({...state, loading: true })}
            className={classes.submit}
            disabled={isInvalid}
          >
            {loading ?
              <CircularProgress className={classes.progress} />
              :
              'Submit'
            }
          </Button>
        </form>
        {error &&
          <Grid container justify="center">
            <Grid item xs={12}>
              {error.message}
            </Grid>
          </Grid>
        }
      </div>
    </Container>
  );
};

ChangeTempPw.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  withRouter,
)(ChangeTempPw);