import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import App from 'components/App';
import { I18n } from 'aws-amplify';
import dict from 'constants/languages';
import settings from 'constants/settings';
import * as serviceWorker from './serviceWorker';

const { language = null } = settings;

// set translation dictionary and language
I18n.putVocabularies(dict);
if (language) {
  I18n.setLanguage(language);
}

ReactDOM.render(
  <div>
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <App />
  </div>,
  document.getElementById('root')
);
module.hot.accept();
serviceWorker.unregister();
