import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LandingPage from 'screens/Landing';
import SignUpPage from 'screens/SignUp';
import SignInPage from 'screens/SignIn';
import PasswordForgetPage from 'screens/PasswordForget';
import PasswordChangePage from 'screens/PasswordChange';
import HomePage from 'screens/Home';
import ProfilePage from 'screens/Profile';
import CustomersPage from 'screens/Customers';
import MessageCenter from 'screens/MessageCenter';
import AdminPage from 'screens/Admin';
import HomeIcon from '@material-ui/icons/Home';
import MessageIcon from '@material-ui/icons/Mail';
import EnergyIcon from '@material-ui/icons/Power';
import WarningIcon from '@material-ui/icons/Warning';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CustomersIcon from '@material-ui/icons/SupervisorAccount';
import AccountIcon from '@material-ui/icons/AccountCircle';
import AboutIcon from '@material-ui/icons/Info';
import { AuthContext, AuthBouncer } from 'components/Amplify';

export const ROUTES = {
  HOME: {
    name: 'Home',
    pathname: '/home',
    component: HomePage,
    icon: <HomeIcon />,
    auth: true,
    roles: ['USER', 'ADMIN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Home',
        pathname: '/home',
        state: { screen: 'INDEX' }
      }
    }
  },
  CUSTOMERS: {
    name: 'Customers',
    pathname: '/customers',
    component: CustomersPage,
    icon: <CustomersIcon />,
    auth: true,
    roles: ['USER', 'ADMIN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Customers',
        pathname: '/customers',
        state: { screen: 'INDEX' }
      },
      DETAILS: {
        text: 'Details',
        pathname: '/customers',
        state: { screen: 'DETAILS' }
      }
    }
  },
  MESSAGE_CENTER: {
    name: 'Communications',
    pathname: '/messagecenter',
    component: MessageCenter,
    icon: <MessageIcon />,
    auth: true,
    roles: ['USER', 'ADMIN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Communications',
        pathname: '/messagecenter',
        state: { screen: 'INDEX' }
      },
      NEW: {
        text: 'Create New Message',
        pathname: '/messagecenter',
        state: { screen: 'NEW' }
      },
      CREATE: {
        text: 'Create New Message',
        pathname: '/messagecenter',
        state: { screen: 'CREATE' }
      },
      EDIT: {
        text: 'Edit Message',
        pathname: '/messagecenter',
        state: { screen: 'EDIT' }
      }
    }
  },
  INSIGHTS: {
    name: 'Insights',
    pathname: '/insights',
    component: HomePage,
    icon: <WarningIcon />,
    auth: true,
    roles: ['HIDDEN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Insights',
        pathname: '/insights',
        state: { screen: 'INDEX' }
      }
    }
  },
  ENERGY_PROGRAMS: {
    name: 'Energy Programs',
    pathname: '/energyprograms',
    component: HomePage,
    icon: <EnergyIcon />,
    auth: true,
    roles: ['HIDDEN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Energy Programs',
        pathname: '/energyprograms',
        state: { screen: 'INDEX' }
      }
    }
  },
  SETTINGS: {
    name: 'Settings',
    pathname: '/settings',
    component: HomePage,
    icon: <SettingsIcon />,
    auth: true,
    roles: ['HIDDEN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Settings',
        pathname: '/settings',
        state: { screen: 'INDEX' }
      }
    }
  },
  DOWNLOADS: {
    name: 'Downloads',
    pathname: '/downloads',
    component: HomePage,
    icon: <DownloadIcon />,
    auth: true,
    roles: ['HIDDEN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Downloads',
        pathname: '/downloads',
        state: { screen: 'INDEX' }
      }
    }
  },
  HELP: {
    name: 'Help',
    pathname: '/help',
    component: HomePage,
    icon: <HelpIcon />,
    auth: true,
    roles: ['HIDDEN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Help',
        pathname: '/help',
        state: { screen: 'INDEX' }
      }
    }
  },
  ADMIN: {
    name: 'Admin',
    pathname: '/admin',
    component: HomePage,
    icon: <AboutIcon />,
    auth: true,
    roles: ['ADMIN'],
    permisions: [],
    screens: {
      INDEX: {
        text: 'Admin',
        pathname: '/admin',
        state: { screen: 'INDEX' }
      }
    }
  },
  LANDING: {
    name: 'Landing Page',
    pathname: '/',
    component: LandingPage,
    icon: <HomeIcon />,
    auth: false,
    roles: [],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Landing Page',
        pathname: '/',
        state: { screen: 'INDEX' }
      }
    }
  },
  SIGN_UP: {
    name: 'Sign Up',
    pathname: '/signup',
    component: SignUpPage,
    icon: <AboutIcon />,
    auth: false,
    roles: [],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Sign Up',
        pathname: '/signup',
        state: { screen: 'INDEX' }
      }
    }
  },
  SIGN_IN: {
    name: 'Sign In',
    pathname: '/signin',
    component: SignInPage,
    icon: <AboutIcon />,
    auth: false,
    roles: [],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Sign In',
        pathname: '/signin',
        state: { screen: 'INDEX' }
      }
    }
  },
  PROFILE: {
    name: 'Profile',
    pathname: '/profile',
    component: ProfilePage,
    icon: <AccountIcon />,
    auth: true,
    roles: ['HIDDEN'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Profile',
        pathname: '/profile',
        state: { screen: 'INDEX' }
      }
    }
  },
  PASSWORD_FORGET: {
    name: 'Forgot Password',
    pathname: '/forgot',
    component: PasswordForgetPage,
    icon: <AboutIcon />,
    auth: false,
    roles: [],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Forgot Password',
        pathname: '/forgot',
        state: { screen: 'INDEX' }
      },
      RESET_PASSWORD: {
        text: 'Reset Password',
        pathname: '/forgot',
        state: { screen: 'RESET_PASSWORD' }
      },
      SEND_CODE: {
        text: 'Send Code',
        pathname: '/forgot',
        state: { screen: 'SEND_CODE' }
      }
    }
  },
  PASSWORD_CHANGE: {
    name: 'Change Password',
    pathname: '/passwordchange',
    component: PasswordChangePage,
    icon: <AboutIcon />,
    auth: true,
    roles: ['USER'],
    permissions: [],
    screens: {
      INDEX: {
        text: 'Change Password',
        pathname: '/passwordchange',
        state: { screen: 'INDEX' }
      }
    }
  }
};

// the arrays of breadcrumbs that get rendered in the breadcrumb bar
export const BC_TRAILS = [
  {
    // HOME
    pathname: ROUTES.HOME.screens.INDEX.pathname,
    screen: ROUTES.HOME.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.HOME.screens.INDEX }]
  },
  {
    // CUSTOMERS
    pathname: ROUTES.CUSTOMERS.screens.INDEX.pathname,
    screen: ROUTES.CUSTOMERS.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.CUSTOMERS.screens.INDEX }]
  },
  {
    pathname: ROUTES.CUSTOMERS.screens.DETAILS.pathname,
    screen: ROUTES.CUSTOMERS.screens.DETAILS.state.screen,
    trail: [
      { isLink: true, data: ROUTES.CUSTOMERS.screens.INDEX },
      { isLink: false, data: ROUTES.CUSTOMERS.screens.DETAILS }
    ]
  },
  {
    // MESSAGE_CENTER
    pathname: ROUTES.MESSAGE_CENTER.screens.INDEX.pathname,
    screen: ROUTES.MESSAGE_CENTER.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.MESSAGE_CENTER.screens.INDEX }]
  },
  {
    pathname: ROUTES.MESSAGE_CENTER.screens.NEW.pathname,
    screen: ROUTES.MESSAGE_CENTER.screens.NEW.state.screen,
    trail: [
      { isLink: true, data: ROUTES.MESSAGE_CENTER.screens.INDEX },
      { isLink: false, data: ROUTES.MESSAGE_CENTER.screens.NEW }
    ]
  },
  {
    pathname: ROUTES.MESSAGE_CENTER.screens.CREATE.pathname,
    screen: ROUTES.MESSAGE_CENTER.screens.CREATE.state.screen,
    trail: [
      { isLink: true, data: ROUTES.MESSAGE_CENTER.screens.INDEX },
      { isLink: false, data: ROUTES.MESSAGE_CENTER.screens.CREATE }
    ]
  },
  {
    pathname: ROUTES.MESSAGE_CENTER.screens.EDIT.pathname,
    screen: ROUTES.MESSAGE_CENTER.screens.EDIT.state.screen,
    trail: [
      { isLink: true, data: ROUTES.MESSAGE_CENTER.screens.INDEX },
      { isLink: false, data: ROUTES.MESSAGE_CENTER.screens.EDIT }
    ]
  },
  {
    // INSIGHTS
    pathname: ROUTES.INSIGHTS.screens.INDEX.pathname,
    screen: ROUTES.INSIGHTS.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.INSIGHTS.screens.INDEX }]
  },
  {
    // ENERGY_PROGRAMS
    pathname: ROUTES.ENERGY_PROGRAMS.screens.INDEX.pathname,
    screen: ROUTES.ENERGY_PROGRAMS.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.ENERGY_PROGRAMS.screens.INDEX }]
  },
  {
    // SETTINGS
    pathname: ROUTES.SETTINGS.screens.INDEX.pathname,
    screen: ROUTES.SETTINGS.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.SETTINGS.screens.INDEX }]
  },
  {
    // DOWNLOADS
    pathname: ROUTES.DOWNLOADS.screens.INDEX.pathname,
    screen: ROUTES.DOWNLOADS.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.DOWNLOADS.screens.INDEX }]
  },
  {
    // HELP
    pathname: ROUTES.HELP.screens.INDEX.pathname,
    screen: ROUTES.HELP.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.HELP.screens.INDEX }]
  },
  {
    // ADMIN
    pathname: ROUTES.ADMIN.screens.INDEX.pathname,
    screen: ROUTES.ADMIN.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.ADMIN.screens.INDEX }]
  },
  {
    // LANDING
    pathname: ROUTES.LANDING.screens.INDEX.pathname,
    screen: ROUTES.LANDING.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.LANDING.screens.INDEX }]
  },
  {
    // SIGN_UP
    pathname: ROUTES.SIGN_UP.screens.INDEX.pathname,
    screen: ROUTES.SIGN_UP.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.SIGN_UP.screens.INDEX }]
  },
  {
    // SIGN_IN
    pathname: ROUTES.SIGN_IN.screens.INDEX.pathname,
    screen: ROUTES.SIGN_IN.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.SIGN_IN.screens.INDEX }]
  },
  {
    // PROFILE
    pathname: ROUTES.PROFILE.screens.INDEX.pathname,
    screen: ROUTES.PROFILE.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.PROFILE.screens.INDEX }]
  },
  {
    // PASSWORD_FORGET
    pathname: ROUTES.PASSWORD_FORGET.screens.INDEX.pathname,
    screen: ROUTES.PASSWORD_FORGET.screens.INDEX.state.screen,
    trail: [
      { isLink: false, data: ROUTES.PASSWORD_FORGET.screens.INDEX },
      { isLink: false, data: ROUTES.PASSWORD_FORGET.screens.SEND_CODE }
    ]
  },
  {
    pathname: ROUTES.PASSWORD_FORGET.screens.RESET_PASSWORD.pathname,
    screen: ROUTES.PASSWORD_FORGET.screens.RESET_PASSWORD.state.screen,
    trail: [
      { isLink: true, data: ROUTES.PASSWORD_FORGET.screens.INDEX },
      { isLink: true, data: ROUTES.PASSWORD_FORGET.screens.SEND_CODE },
      { isLink: false, data: ROUTES.PASSWORD_FORGET.screens.RESET_PASSWORD }
    ]
  },
  {
    pathname: ROUTES.PASSWORD_FORGET.screens.SEND_CODE.pathname,
    screen: ROUTES.PASSWORD_FORGET.screens.SEND_CODE.state.screen,
    trail: [
      { isLink: true, data: ROUTES.PASSWORD_FORGET.screens.INDEX },
      { isLink: false, data: ROUTES.PASSWORD_FORGET.screens.SEND_CODE }
    ]
  },
  {
    // PASSWORD_CHANGE
    pathname: ROUTES.PASSWORD_CHANGE.screens.INDEX.pathname,
    screen: ROUTES.PASSWORD_CHANGE.screens.INDEX.state.screen,
    trail: [{ isLink: false, data: ROUTES.PASSWORD_CHANGE.screens.INDEX }]
  }
];

const RootRoute = ({ ...rest }) => (
  <AuthContext.Consumer>
    {({ user, role, permissions }) => <Route {...rest} component={user ? HomePage : LandingPage} />}
  </AuthContext.Consumer>
);

export const Routes = () => (
  <Switch>
    <RootRoute exact path="/" />
    {Object.values(ROUTES).map(({ pathname, component, roles, name }, index) => (
      <AuthBouncer exact path={pathname} redirectPath="/" roles={roles} key={name + pathname}>
        <Route exact path={pathname} component={component} />
      </AuthBouncer>
    ))}
    <Redirect to="/" />
  </Switch>
);
