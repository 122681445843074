import gql from 'graphql-tag';

export const QUERY_CUSTOMERS_BY_EMAIL = gql`
  query customersByEmail($filters: CustomersByEmailInput) {
    customersByEmail(filters: $filters) {
      data {
        userId
        email
      }
      emailsNotFound
    }
  }
`;

const QUERY_SERVICE_PROVIDER_CUSTOMERS = gql`
  query {
    customers {
      userId
      email
      familyName
      gender
      givenName
      phoneNumber
      property {
        userId
        houseId
        address
        city
        latlon {
          lat
          lon
        }
        members {
          role
          userId
        }
        postalCode
        propertyType
      }
      serviceProviderId
      timeOffset {
        dstOffset
        rawOffset
      }
      devices {
        hasThermostats
        hasSmokeDetectors
        hasContacts
        hasMotions
        hasLights
        hasWaterDetectors
        hasCameras
      }
      notifications {
        hasActiveAlerts
        hasActiveMessages
        hasActiveBanners
        hasActiveMedals
      }
    }
  }
`;
export default QUERY_SERVICE_PROVIDER_CUSTOMERS;