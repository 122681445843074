import React, { useState } from 'react';

const CharLimit = props => {
  const { length, maxLength = 0, width = 0, disabled = false } = props

  const disabledColor = '#BDBDBD';

  const style = {
    width: width,
    display: 'flex',
    justifyContent: 'flex-end'
  }

  if (disabled) {
    style.color = disabledColor;
  }

  return (
    <div style={style}>
      <span>{length}/{maxLength}</span>
    </div>
  );
};

export default CharLimit;