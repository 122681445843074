import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql } from 'react-apollo';
import classNames from 'classnames';
import { QUERY_CUSTOMERS_BY_EMAIL } from 'constants/GraphQLData';
import CSVReader from "react-csv-reader";
import { useQuery } from '@apollo/react-hooks';
import CustomButton from 'components/Custom/CustomButton';
import { CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';
import ModalDialog from 'components/Custom/modalDialog';

const LIST_VIEW_DIMENSIONS = [600, 200]; // WIDTH, HEIGHT

const UsersList = ({ users }) => { 
  if (!_.isNil(users) && !_.isEmpty(users)) {
    return (
      <div style={{ width: LIST_VIEW_DIMENSIONS[0], height: LIST_VIEW_DIMENSIONS[1], overflowY: 'scroll' }}>
        <ul style={{
          listStyle: 'none',
          padding: 0,
          margin: 0
        }}>
            {users.map((item, index) => {
              return (
                <CustomDataItem key={item} title={item} data={''} />
              )
            })}
        </ul>
      </div>
    );
  } else {
    return (
      <div style={{ width: LIST_VIEW_DIMENSIONS[0], height: LIST_VIEW_DIMENSIONS[1] }}>
        <span>No selected users. (Delivery will default to all users.)</span>
      </div>
    )
  }
};

const EmailsNotFoundList = ({ emailsNotFound }) => {
  if (!_.isNil(emailsNotFound) && !_.isEmpty(emailsNotFound)) {
    return (
      <div style={{ backgroundColor: 'pink' }}>
        <span style={{ fontSize: '1.2em' }}>The following emails did not match any users in the database:</span>
        <div style={{ width: LIST_VIEW_DIMENSIONS[0], height: 0.5*LIST_VIEW_DIMENSIONS[1], overflowY: 'scroll' }}>
          <ul style={{
            listStyle: 'none',
            padding: 0,
            margin: 0
          }}>
              {emailsNotFound.map((item, index) => {
                return (
                  <li key={item}>{item}</li>
                )
              })}
          </ul>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const UsersEmailsList = ({ usersEmails }) => {
  if (!_.isNil(usersEmails) && !_.isEmpty(usersEmails)) {
    return (
      <div style={{ width: LIST_VIEW_DIMENSIONS[0], height: LIST_VIEW_DIMENSIONS[1], overflowY: 'scroll' }}>
        {/* <th>User ID</th>
        <th>Email</th> */}
        <ul style={{
          listStyle: 'none',
          padding: 0,
          margin: 0
        }}>
          {usersEmails.map((item, index) => {
            const { userId = '', email = '' } = item
            return (
              <CustomDataItem key={userId} title={userId} data={email} />
            )
          })}
        </ul>
      </div>
    );
  } else {
    return (
      <div style={{ width: LIST_VIEW_DIMENSIONS[0], height: LIST_VIEW_DIMENSIONS[1] }}>
        <span>No users fetched from CSV email list.</span>
      </div>
    )
  }
};

export const CSVFetchUsersModal = props => {
  const { selectedUsers, setSelectedUsers, setUsersSelectionMethod, setUsers, isOpen=false, close } = props
  const [emailsFromCSV, setEmailsFromCSV] = useState([]);

  // console.log(emailsFromCSV);
  const {
    loading: customersByEmail_loading,
    error: customersByEmail_error,
    data: {
      customersByEmail: {
        data: customersByEmail_data = [],
        emailsNotFound: customersByEmail_emailsNotFound = []
      } = {}
    } = {}
  } = useQuery(QUERY_CUSTOMERS_BY_EMAIL, {
    variables: { filters: { emails: emailsFromCSV } },
    skip: !isOpen,
  });
  // console.log('loading:', customersByEmail_loading);
  // console.log('error:', customersByEmail_error);
  // console.log('data:', customersByEmail_data);
  // console.log(emailsFromCSV);
  const foundVsTotalString = !_.isEmpty(customersByEmail_data) ? `${customersByEmail_data.length}/${emailsFromCSV.length}` : null;

  const handleFileUpload = (data) => {
    const userEmails = data[0];
    // console.log(userEmails);
    setEmailsFromCSV(userEmails);
  };

  const replaceSelectedUsers = () => {
    if (!_.isNil(customersByEmail_data)) {
      const newUsers = customersByEmail_data.map((item, index) => {
        const { userId } = item;
        return userId;
      });
      setSelectedUsers(newUsers);

      // also set the radio toggle and set the users to the selected users
      setUsersSelectionMethod('select');
      setUsers(newUsers);
    }
  };

  return (
    <ModalDialog
      title='Fetch Users from CSV'
      isOpen={isOpen}
      close={close}
    >
      <div>
        <div>
          <CustomHeading small>Current Selected Users:</CustomHeading>
          <UsersList users={selectedUsers} />
          <CustomHeading small>Users fetched from CSV: {foundVsTotalString}</CustomHeading>
          <UsersEmailsList usersEmails={customersByEmail_data} />
          <EmailsNotFoundList emailsNotFound={customersByEmail_emailsNotFound} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div style={{ display: 'flex' }}>
            <CustomButton
              disabled={false}
              onClick={close}
              text="Close"
            />
            <div style={{ width: 10 }} />
            <div style={{ paddingTop: 7.5 }}>
              <CSVReader
                cssClass="react-csv-input"
                onFileLoaded={handleFileUpload}
              />
            </div>
          </div>
          <CustomButton
            disabled={false}
            text="Set"
            onClick={replaceSelectedUsers}
            dark
          />
        </div>
      </div >
    </ModalDialog>
  );
}