import gql from 'graphql-tag';

const QUERY_SERVICE_PROVIDER_USER = gql`
  query serviceProviderUser {
    serviceProviderUser {
      userId
      units
      gender
      timeOffset {
        dstOffset
        rawOffset
      }
      givenName
      familyName
      serviceProviderId
      email
      phoneNumber
      avatar
      displayName
      theme
      city
      contactEmail
      country
      logo
      primaryAddress
      state
      serviceProviderLogo
    	serviceProviderTimezone
    }
  }
`;
export default QUERY_SERVICE_PROVIDER_USER;
