import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ROUTES } from 'components/Routes';

const styles = theme => ({
  item: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      borderLeft: `5px solid ${theme.palette.primary.light}`,
      paddingLeft: 11,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '& div:nth-child(1)': {
        color: theme.palette.primary.light
      },
      // '& navIcon': {
      //   color: theme.palette.primary.light
      // },
      '& div:nth-child(2)': {}
    }
  },
  active: {
    borderLeft: `5px solid ${theme.palette.primary.light}`,
    paddingLeft: 11,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '& div:nth-child(1)': {
      color: theme.palette.primary.light
    },
    '& div:nth-child(2)': {}
  },
  navIcon: {
    color: theme.palette.primary.contrastText
  },
  navText: {
    color: theme.palette.primary.contrastText
  }
});

// Warning: Function components cannot be given refs.Attempts to access this ref will fail.Did you mean to use React.forwardRef() ?
// https://github.com/mui-org/material-ui/issues/15903
// https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
// https://material-ui.com/guides/composition/#react-router-demo
// https://reactjs.org/docs/forwarding-refs.html
const NavItem = props => {
  const { classes, route } = props;

  return (
    <ListItem
      className={classes.item}
      activeClassName={classes.active}
      component={NavLink}
      to={ROUTES[route].pathname}
      title={ROUTES[route].name}
    >
      <ListItemIcon className={classes.navIcon}>{ROUTES[route].icon}</ListItemIcon>
      <ListItemText className={classes.navText} primary={ROUTES[route].name} />
    </ListItem>
  );
};

NavItem.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(NavItem);
