import React, { useState } from 'react';
import { Select, MenuItem, Radio, Switch, Button, TextField, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { DefaultUiButton, buttonLabelOptions } from 'constants/messageUiDefaults';
import PropTypes from 'prop-types';
import CharLimit from 'screens/MessageCenter/Create/charLimit'
import OpenInNew from '@material-ui/icons/OpenInNew';


const useStyles = makeStyles(theme => ({
  frame: {
    maxWidth: 300,
    padding: 32,
    marginRight: 40,
    marginBottom: 32,
    border: '1px solid lightgrey'
  },
  flex: {
    display: 'flex'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  disabled: {
    color: theme.palette.grey.disabled
  },
  blue: {
    color: theme.palette.primary.light
  },
}));

const inputFieldWidth = 180;

const AddAButton = props => {
  const classes = useStyles();

  const { button, setButton, buttonNumber, maxButtonLabelLength } = props;
  if (_.isNil(button)) {
    button = DefaultUiButton;
  } else {
    _.map(DefaultUiButton, (val, key) => {
      if (_.isNil(button[key])) {
        button[key] = val;
      };
    });
  };

  const returnInitialUiState = () => {
    // make sure the ui input fields will be cleaned of "notGiven" values
    const referenceButton = { ...button };
    if (referenceButton.label === 'notGiven') {
      referenceButton.label = '';
    };
    if (referenceButton.url === 'notGiven') {
      referenceButton.url = '';
    };

    /*
      uiState has properties:
      {
        enable: bool
        labelType: enum = select, custom
        selectedLabelNumber: number,
        customLabelString: string,
        action: enum = close, url
        url: string,
      }
    */
    const initialUiState = {};
    if (referenceButton.enable) {
      initialUiState.enable = true;
      const labelIndex = _.findIndex(buttonLabelOptions, referenceButton.label);
      if (labelIndex === -1) {
        initialUiState.labelType = 'custom';
        initialUiState.selectedLabelNumber = -1;
        initialUiState.customLabelString = referenceButton.label;
      } else {
        initialUiState.labelType = 'select';
        initialUiState.selectedLabelNumber = labelIndex;
        initialUiState.customLabelString = '';
      }
      switch (referenceButton.action) {
        case 'close':
          initialUiState.action = 'close';
          initialUiState.url = '';
          break;
        case 'url':
          initialUiState.action = 'url';
          initialUiState.url = referenceButton.url;
          break;
        default:
          initialUiState.action = 'close';
          initialUiState.url = '';
          break;
      }
    } else {
      initialUiState.enable = false;
      initialUiState.labelType = 'select';
      initialUiState.selectedLabelNumber = 0;
      initialUiState.customLabelString = '';
      initialUiState.action = 'close';
      initialUiState.url = '';
    }
    return initialUiState;
  };

  const [uiState, setUiState] = useState(returnInitialUiState);

  const handleEnableChange = (evt) => {
    const enable = evt.target.checked;
    let newState = null;
    let newButton = null;
    newState = { ...uiState, enable };
    setUiState(newState);
    newButton = { ...button, enable };
    setButton(newButton, buttonNumber);
  };
  const handleLabelTypeChange = (evt) => {
    const labelType = evt.target.value;
    let newState = null;
    let newButton = null;
    switch (labelType) {
      case 'select':
        newState = { ...uiState, labelType };
        setUiState(newState);
        newButton = {
          ...button,
          label: buttonLabelOptions[uiState.selectedLabelNumber]
        };
        setButton(newButton, buttonNumber);
        break;
      case 'custom':
        newState = { ...uiState, labelType };
        setUiState(newState);
        newButton = {
          ...button,
          label: uiState.customLabelString
        };
        setButton(newButton, buttonNumber);
        break;
    }
  };
  const handleSelectedLabelChange = (evt) => {
    const selectedLabelNumber = evt.target.value;
    let newState = null;
    let newButton = null;
    newState = { ...uiState, selectedLabelNumber };
    setUiState(newState);
    newButton = {
      ...button,
      label: buttonLabelOptions[selectedLabelNumber]
    };
    setButton(newButton, buttonNumber);
  };
  const handleCustomLabelChange = (evt) => {
    const customLabelString = evt.target.value;
    if (customLabelString && customLabelString.length <= maxButtonLabelLength) {
      let newState = null;
      let newButton = null;
      newState = { ...uiState, customLabelString };
      setUiState(newState);
      newButton = {
        ...button,
        label: customLabelString
      };
      setButton(newButton, buttonNumber);
    }
  };
  const handleActionChange = (evt) => {
    const action = evt.target.value;
    let newState = null;
    let newButton = null;
    newState = { ...uiState, action };
    setUiState(newState);
    newButton = { ...button, action };
    setButton(newButton, buttonNumber);
  };
  const handleUrlChange = (evt) => {
    const url = evt.target.value;
    if(url) {
      let newState = null;
      let newButton = null;
      newState = { ...uiState, url };
      setUiState(newState);
      newButton = { ...button, url };
      setButton(newButton, buttonNumber);
    }
  };

  const labelTypeDisabled = !uiState.enable;
  const selectedLabelDisabled = !uiState.enable || !(uiState.labelType === 'select');
  const customLabelDisabled = !uiState.enable || !(uiState.labelType === 'custom');
  const actionDisabled = !uiState.enable;
  const closeActionDisabled = !uiState.enable || !(uiState.action === 'close');
  const urlDisabled = !uiState.enable || !(uiState.action === 'url');

  return (
    <div>
      <div className={classes.frame}>
        <div className={classes.flexEnd}>
          <div style={{ position: 'relative', left: 12}}>
            <Switch
              checked={uiState.enable}
              onChange={handleEnableChange}
            />
          </div>
        </div>
        <div >
          <InputLabel
            shrink
            disabled={!uiState.enable}
          >Button Label</InputLabel>
          <div className={classes.flex}>
            <Radio
              checked={uiState.enable && (uiState.labelType === 'select')}
              onChange={handleLabelTypeChange}
              value='select'
              disabled={labelTypeDisabled}
            />
            <Select 
              style={{ width: inputFieldWidth, height: 32 }}
              value={uiState.selectedLabelNumber}
              onChange={handleSelectedLabelChange}
              disabled={selectedLabelDisabled}
            >
            {
              buttonLabelOptions.map((item, index) => {
                return (
                  <MenuItem key={item} value={index}>{item}</MenuItem>
                )
              })
            }
            </Select>
          </div>
          <div className={classes.flex}>
            <div>
              <Radio
                checked={uiState.enable && (uiState.labelType === 'custom')}
                onChange={handleLabelTypeChange}
                value='custom'
                disabled={labelTypeDisabled}
              />
              <div style={{ height: 20 }} />
            </div>
            <div>
              <TextField
                style={{ width: inputFieldWidth }}
                value={uiState.customLabelString}
                onChange={handleCustomLabelChange}
                disabled={customLabelDisabled}
              />
              <CharLimit
                length={uiState.customLabelString && uiState.customLabelString.length}
                maxLength={maxButtonLabelLength}
                width={inputFieldWidth}
                disabled={customLabelDisabled}
              />
            </div>
          </div>
        </div>
        <div>
          <InputLabel shrink>Action</InputLabel>
          <div>
            <Radio
              checked={uiState.enable && (uiState.action === 'close')}
              onChange={handleActionChange}
              value='close'
              disabled={actionDisabled}
            />
            <span className={closeActionDisabled ? classes.disabled : null}>Close (default)</span>
          </div>
          <div className={classes.flex}>
            <Radio
              checked={uiState.enable && (uiState.action === 'url')}
              onChange={handleActionChange}
              value='url'
              disabled={actionDisabled}
            />
            <TextField
              style={{ width: inputFieldWidth }}
              placeholder="Link"
              value={uiState.url}
              onChange={handleUrlChange}
              disabled={urlDisabled}
            />
            <OpenInNew
              className={urlDisabled ? classes.disabled : classes.blue}
              onClick={() => {
                if (!urlDisabled) {
                  window.open(uiState.url, '_blank');
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

AddAButton.defaultProps = {
  maxButtonLabelLength: 0,
  button: DefaultUiButton,
  buttonNumber: 1,
  setButton: null
};

AddAButton.propTypes = {
  maxButtonLabelLength: PropTypes.number,
  button: PropTypes.object,
  buttonNumber: PropTypes.number,
  setButton: PropTypes.func,
};

export default AddAButton;
