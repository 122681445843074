import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { graphql } from 'react-apollo';
import { QUERY_SERVICE_PROVIDER_CUSTOMERS } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Table from 'screens/Customers/table';
import Details from 'screens/Customers/details';
import { ROUTES } from 'components/Routes';
import { customerFields, defaultColumns } from 'constants/customerDataFields';
import ExpansionPanel from 'screens/MessageCenter/Create/expansionPanel';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';
import TextEditor from 'components/Custom/TextEditor';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'none'
  },
  box: {
    border: '1px solid grey',
    width: 220,
    height: 50,
  }
}));

const NotificationPreview = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomHeading small>Preview</CustomHeading>
      <div className={classes.box}></div>
    </div>
  );
};

NotificationPreview.propTypes = {};

NotificationPreview.defaultProps = {};

export default NotificationPreview;