import gql from 'graphql-tag';

export const QUERY_MESSAGE = gql`
  query message($id: String) {
    messages (messageIds: [
      $id
    ]) 
    {
      messageLabel
      stage
      messageId
      serviceProviderId
      messageType
      deliveryFormats {
        emailNotification
        smsNotification
        osNotification
        banner
        messageBody
      }
      banner
      buttons {
        action
        enable
        label
        type
        url
        value
      }
      createdAt
      createdBy
      criticality
      filters {
        attribute 
        value {
          boolean 
          number 
          string
        }
        condition
      }
      images {
        bannerImage
        fullImage
        logoImage
        teaserImage
      }
      logo
      messageAudio
      messageIcon
      messageImage
      messageTitle
      messageVideo
      notificationType
      providedBy
      reference
      status
      summary
      text
      texts {
        fullText
        notificationText
        subjectText
        summaryText
        teaserText
      }
      timeStart
      timeEnd
      users
    }
  }
`;

export const QUERY_DRAFT = gql`
  query draft($id: String) {
    drafts (draftIds: [
      $id
    ]) 
    {
      messageLabel
      draftId
      serviceProviderId
      messageType
      deliveryFormats {
        emailNotification
        smsNotification
        osNotification
        banner
        messageBody
      }
      buttons {
        action
        enable
        label
        type
        url
        value
      }
      accessLevel
      createdAt
      createdBy
      criticality
      filters {
        attribute 
        value {
          boolean 
          number 
          string
        }
        condition
      }
      images {
        bannerImage
        fullImage
        logoImage
        teaserImage
      }
      messageAudio
      messageIcon
      messageVideo
      providedBy
      texts {
        fullText
        notificationText
        subjectText
        summaryText
        teaserText
      }
      users
    }
  }
`;

export const QUERY_MESSAGES_DRAFTS_FOR_TABLE = gql`
  query {
    messages {
      messageLabel
      stage
      messageId
      messageType
      createdAt
      createdBy
      texts {
        subjectText
      }
    }
    drafts {
      messageLabel
      draftId
      messageType
      createdAt
      createdBy
      texts {
        subjectText
      }
    }
  }
`;

export const MUTATION_UPDATE_DRAFT = gql`
  mutation updateDraft($draft: InputDraft) {
    updateDraft(draft: $draft) {
      messageLabel
      draftId
      serviceProviderId
      messageType
      deliveryFormats {
        emailNotification
        smsNotification
        osNotification
        banner
        messageBody
      }
      buttons {
        action
        enable
        label
        type
        url
        value
      }
      accessLevel
      createdAt
      createdBy
      criticality
      filters {
        attribute
        value {
          boolean
          number
          string
        }
        condition
      }
      images {
        bannerImage
        fullImage
        logoImage
        teaserImage
      }
      messageAudio
      messageIcon
      messageVideo
      providedBy
      texts {
        fullText
        notificationText
        subjectText
        summaryText
        teaserText
      }
      users
    }
  }
`;

export const MUTATION_CREATE_MESSAGE = gql`
  mutation createMessage($message: CreateMessage!) {
    createMessage(message: $message) {
      messageLabel
      messageId
      serviceProviderId
      messageType
      deliveryFormats {
        emailNotification
        smsNotification
        osNotification
        banner
        messageBody
      }
      banner
      buttons {
        action
        enable
        label
        type
        url
        value
      }
      createdAt
      createdBy
      criticality
      filters {
        attribute 
        value {
          boolean 
          number 
          string
        }
        condition
      }
      images {
        bannerImage
        fullImage
        logoImage
        teaserImage
      }
      logo
      messageAudio
      messageIcon
      messageImage
      messageTitle
      messageVideo
      notificationType
      providedBy
      reference
      status
      summary
      text
      texts {
        fullText
        notificationText
        subjectText
        summaryText
        teaserText
      }
      timeStart
      timeEnd
      users
    }
  }
`;

export const MUTATION_EDIT_MESSAGE = gql`
  mutation editMessage($message: EditMessage!) {
    editMessage(message: $message) {
      messageLabel
      messageId
      buttons {
        action
        enable
        label
        type
        url
        value
      }
      criticality
      images {
        bannerImage
        fullImage
        logoImage
        teaserImage
      }
      messageIcon
      messageAudio
      messageVideo
      texts {
        fullText
        notificationText
        subjectText
        summaryText
        teaserText
      }
    }
  }
`;
