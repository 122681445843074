import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, intersection } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { ROUTES } from 'components/Routes';
import { sidebarAuth, sidebarUnAuth } from 'constants/menus';
import { AuthBouncer } from 'components/Amplify';
import NavItem from './navItem';

const logo = require('images/alana-logo.svg');
const icon = require('images/alana-icon.svg');

const drawerWidth = 240;
const mainBarHeight = 64;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  itemList: {
    backgroundColor: theme.palette.primary.dark,
    padding: 0
  },
  logoDiv: {
    cursor: 'pointer',
    height: mainBarHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `linear-gradient(${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    '& img': {
      height: '26px',
      objectFit: 'cover'
    },
    '& div': {}
  },
  colorFill: {
    backgroundColor: theme.palette.primary.dark,
    flex: '1 1 auto',
    width: '100%'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    border: 0,
    backgroundColor: theme.palette.primary.dark,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    border: 0, 
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    border: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

const MiniDrawer = props => {
  const { classes, theme, open = false, setOpen = () => void 0 } = props;

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
    >
      <div className={classes.logoDiv} onClick={setOpen}>
        <div>
          <img src={open ? logo : icon} alt="" />
        </div>
      </div>
      <List className={classes.itemList}>
        {sidebarAuth.map(route => (
          <AuthBouncer key={route} roles={ROUTES[route].roles}>
            <NavItem route={route} />
          </AuthBouncer>
        ))}
      </List>
      <List className={classes.itemList}>
        {sidebarUnAuth.map(route => (
          <AuthBouncer key={route} unauth>
            <NavItem route={route} />
          </AuthBouncer>
        ))}
      </List>
      <div className={classes.colorFill} />
    </Drawer>
  );
};

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);
