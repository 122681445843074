import React, { Component } from 'react';
import { compose } from 'recompose';
import { isEmpty, intersection } from 'lodash';
// import * as ROLES from 'constants/roles';

class AdminPage extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     loading: false,
  //     users: [],
  //   };
  // }

  // componentDidMount() {
  //   this.setState({ loading: true });

  //   this.unsubscribe = this.props.firebase
  //     .users()
  //     .onSnapshot(snapshot => {
  //       let users = [];

  //       snapshot.forEach(doc =>
  //         users.push({ ...doc.data(), uid: doc.id }),
  //       );

  //       this.setState({
  //         users,
  //         loading: false,
  //       });
  //     });
  // }

  // componentWillUnmount() {
  //   this.unsubscribe();
  // }

  render() {
    // const { users, loading } = this.state;

    return (
      <div>
        <h1>Admin</h1>
        <p>The Admin Page is accessible by every signed in admin user.</p>

        {/* {loading && <div>Loading ...</div>}

        <UserList users={users} /> */}
      </div>
    );

    // return (
    //   <AuthUserContext.Consumer>
    //     {authUser => (
    //     <div>
    //       <h1>Admin</h1>
    //       {loading && <div>Loading ...</div>}

    //       <UserList users={users} />
    //     </div>
    //     )}
    //   </AuthUserContext.Consumer>
    // );
  }
}

// const UserList = ({ users }) => (
//   <div>
//   Users
//   <ul>
//     {users.map(user => (
//       <li key={user.uid}>
//         <span>
//           <strong>ID:</strong> {user.uid}
//         </span>
//         <span>
//           <strong>E-Mail:</strong> {user.email}
//         </span>
//         <span>
//           <strong>Display name:</strong> {user.displayName}
//         </span>
//       </li>
//     ))}
//   </ul>
//   </div>
// );

// const condition = authUser => !!authUser;

// const condition = authUser =>
//   authUser && !isEmpty(intersection(authUser.roles, ['ADMIN']));

export default AdminPage;
