import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ModalBG from 'components/Custom/modalBackgroundHOC';
import { CustomHeading } from 'components/Custom/customStyledComponents';
import { compose } from 'recompose';
import CloseIcon from '@material-ui/icons/Close';
import {
  Paper,
  Divider
} from '@material-ui/core';
import { flexbox } from '@material-ui/system';

const paperStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: 500,
  },
  paper: {},
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeIcon: {
    color: theme.palette.divider,
  },
  titleBarWrapper: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  contentWrapper: {
    padding: theme.spacing(2),
  }
}));

/*  
  Description:
    a dialog modal
  Props:
    close: function to be called when the user clicks close
    title: a string to be displayed as the title of the dialog
    className: (optional) for styling from parent
  other info:
    its children are rendered in the body of the dialog
*/

export const ModalDialog = props => {
  const classes = paperStyles();
  const { className, close, title, children } = props; // combining in className from props allows layering styles
  return (
    <div className={classNames(classes.root, className)} onClick={evt=>evt.stopPropagation()}>
      <Paper className={classNames(classes.paper)}>
        <div className={classNames(classes.titleBarWrapper)}>
          <div className={classNames(classes.titleBar)}>
            <CustomHeading small>{title}</CustomHeading>
            <CloseIcon onClick={close} className={classNames(classes.closeIcon)}/>
            </div>
        </div>
        <Divider />
        <div className={classNames(classes.contentWrapper)}>
          {children}
        </div>
      </Paper>
    </div>
  );
};

// ModalDialog.propTypes = {
//   children: PropTypes.any,
//   className: PropTypes.any
// };

// ModalDialog.defaultProps = {
//   children: undefined
// };

export default compose(ModalBG)(ModalDialog);
