import React from 'react';
import PropTypes from 'prop-types';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink, createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable } from 'apollo-link';
import { ApolloProvider, Query } from 'react-apollo';
// import { ApolloProvider } from '@apollo/react-hooks';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { getCacheKey } from 'constants/GraphQLData';

const getIdToken = async () => {
  try {
    const session = await Auth.currentSession();
    const { idToken: { jwtToken } = {} } = session;
    // console.log(jwtToken);
    return jwtToken;
  } catch (error) {
    console.log(`error getting token in getIdToken: ${error}`);
    throw new Error({ error });
  }
};

const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
const retryLink = new RetryLink();
const authLink = setContext(async (_, { headers }) => {
  try {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${await getIdToken()}`
      }
    };
  } catch (error) {
    throw new Error({ error });
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: object => getCacheKey(object),
  addTypename: false // did this to avoid adding "__typename" to every object in queries
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink.concat(httpLink) // Chain it with the HttpLink
  ]),
  cache,
});

const Apollo = props => {
  const { children } = props;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default Apollo;

Apollo.propTypes = {
  children: PropTypes.node.isRequired
};
