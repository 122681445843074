import React, { useState, useContext } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Auth } from 'aws-amplify';
import { ROUTES } from 'components/Routes';
import styles from './styles';

const SendCodeForm = props => {
  const INITIAL_STATE = {
    email: '',
    error: null
  };
  const {
    parentSetEmail,
    classes,
    history: { push }
  } = props;
  const [email, setEmail] = useState(INITIAL_STATE.email);
  const [error, setError] = useState(INITIAL_STATE.error);
  const formStates = {
    email: value => setEmail(value)
  };
  const onChange = evt => {
    formStates[evt.target.name](evt.target.value);
  };
  const onSubmit = event => {
    event.preventDefault();
    Auth.forgotPassword(email)
      .then(data => {
        parentSetEmail(email);
        const redirectPath = ROUTES.PASSWORD_FORGET.screens.RESET_PASSWORD;
        push(redirectPath.pathname, redirectPath.state);
      })
      .catch(err => {
        setError(err);
      });
  };
  const isInvalid = email === '';

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={onSubmit}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" name="email" value={email} onChange={onChange} autoFocus />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            disabled={isInvalid}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send Verification Code
          </Button>
          {error && (
            <Grid container justify="center">
              <Grid item xs={12}>
                {error.message}
              </Grid>
            </Grid>
          )}
        </form>
      </div>
    </Container>
  );
};
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(SendCodeForm);
