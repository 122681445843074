import React, { useState } from 'react';
import _ from 'lodash';
import MaterialTable from 'material-table';
import { Modal, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { graphql } from 'react-apollo';
import { QUERY_FULLSCREEN_IMAGES } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Table from 'screens/Customers/table';
import Details from 'screens/Customers/details';
import { ROUTES } from 'components/Routes';
import { customerFields, defaultColumns } from 'constants/customerDataFields';
import ExpansionPanel from 'screens/MessageCenter/Create/expansionPanel';
import MessagePreview from 'screens/MessageCenter/Create/messagePreview';
// import FlexFrame from 'screens/MessageCenter/Create/flexFrame';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';
import ImageSelect from 'screens/MessageCenter/Create/imageSelect';
import TextEditor from 'components/Custom/TextEditor';
import AddAButton from 'screens/MessageCenter/Create/addAButton';
import CharLimit from 'screens/MessageCenter/Create/charLimit'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '20px 0px',
    margin: 'auto',
    width: '80%',
  },
  inputs: {

  },
  preview: {

  },
  'inputs, preview': {

  },
  wrapper: {
    margin: '0px 40px 0px 0px',
    width: 500,
  },
  toolbar: {
  },
  editor: {
    height: 150,
    //
  },
  inlineHeading: {
    display: 'inline'
  },
  addButtons: {
    display: 'flex'
  }
}));

const Message = props => {
  const classes = useStyles();
  const {
    title = '',
    setTitle,
    titleMaxLength = 0,
    data: {
      images: {
        baseURL = '',
        genericImages: { fullscreen: GFIms = [] } = {},
        serviceProviderImages: { fullscreen: SPFIms = [] } = {}
      } = {}
    } = {},
    fullText = '',
    setFullText,
    fullImage = 'notGiven',
    setFullImage,
    maxLength,
    maxButtonLabelLength,
    buttons: [
      button1,
      button2
    ],
    setButton,
    disabled = false,
  } = props;
  
  let images = [...SPFIms, ...GFIms];
  images = images.map(item => {
    return baseURL + item;
  });

  const { titleLength = 0 } = title;

  return (
    <div className={classes.root}>
      <div className={classes.inputs}>
        <div className={classes.flex1}>
          <TextField placeholder="Title" value={title} onChange={setTitle} />
          <CharLimit length={titleLength} maxLength={titleMaxLength} width={182} />
        </div>
        <TextEditor
          disabled={disabled}
          wrapper={classes.wrapper}
          toolbar={classes.toolbar}
          editor={classes.editor}
          markdown={fullText}
          setMarkdown={setFullText}
          maxLength={maxLength}
        />
        <ImageSelect
          images={images}
          selectedImage={fullImage}
          setSelectedImage={setFullImage}
          text="Message Image"
        />
        <CustomHeading medium>Buttons <Typography className={classes.inlineHeading} color="textPrimary">(optional)</Typography></CustomHeading>
        <div className={classes.addButtons}>
          <AddAButton
            button={button1}
            buttonNumber={1}
            setButton={(button) => setButton(button, 1)}
            maxButtonLabelLength={maxButtonLabelLength}
          />
          <AddAButton
            button={button2}
            buttonNumber={2}
            setButton={(button) => setButton(button, 2)}
            maxButtonLabelLength={maxButtonLabelLength}
          />
        </div>
      </div>
      <div className={classes.preview}>
        <MessagePreview />
      </div>
    </div>
  );
};

Message.propTypes = {};

Message.defaultProps = {};

export default compose(
  graphql(QUERY_FULLSCREEN_IMAGES)
)(Message);
