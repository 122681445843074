import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EP from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey.light}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.grey.light}`
    }
  },
  bar: {
    display: 'flex',
    padding: '5px 20px'
  },
  clickablePartOfBar: {
    flex: 1,
    display: 'flex'
  },
  expandIcon: {
    padding: 6,
    width: 38,
    height: 38
  },
  heading: {
    padding: '7px 0px',
    fontWeight: theme.typography.fontWeightRegular
  },
  control: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  openBar: {
    borderBottom: `1px solid ${theme.palette.grey.light}`
  },
  notOpenBar: {
    borderBottom: `1px solid transparent`,
    transition: 'border 0.4s ease',
    transitionTimingFunction: 'step-end'
  },
  openIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.4s ease'
  },
  notOpenIcon: {
    transform: 'rotate(-90deg)',
    transition: 'transform 0.4s ease'
  },
  disabledColor: {
    color: theme.palette.grey.main
  },
  panel: {},
  openPanel: {
    maxHeight: 800, // TODO: get height of child. transition the height instead of the maxHeight.
    transition: 'max-height 0.4s ease',

  },
  notOpenPanel: {
    maxHeight: 0,
    transition: 'max-height 0.4s ease',
    visibility: 'hidden',
    overflow: 'hidden',
  },
  hidden: {
  }
}));


// function for getting previous value
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// this component renders a child in an expandable panel.
// props:
//  -- title: the title to display on the left
//  -- control: 
//       a component passed in from the parent, rendered on the right
//       (for the use case in mind, 'control' will be a switch)
const ExpansionPanel = props => {
  const { title = '', control, alwaysClosed = false, defaultOpen = false, pushOpen = false } = props
  const panelElement = useRef(null);
  const [height, setHeight] = useState(0);
  const classes = useStyles();
  const initiallyOpen = !alwaysClosed && defaultOpen;
  const [state, setState] = useState({ open: initiallyOpen });
  // const [open, setOpen] = useState(!alwaysClosed && defaultOpen);

  const toggleOpen = () => {
    if (!alwaysClosed) {
      setState({...state, open: !state.open});
    }
  }

  /*
    to add the new feature of pushing state.open the expansion panel when toggling on the delivery mode
    while accommodating the existing the design, adding the boolean prop 'pushOpen'.

    when pushOpen goes from false to true, it opens.
  */
  // console.log('prevPush', usePrevious(pushOpen));
  // console.log('push:', pushOpen);
  if (!usePrevious(pushOpen) && pushOpen && !state.open) {
    setState({open: true });
  }

  // if alwaysClosed then make sure it's closed
  if (alwaysClosed && state.open) {
    setState({ open: false });
  }

  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.bar, {
          [classes.openBar]: state.open,
          [classes.notOpenBar]: !state.open
        })}
      >
        <div className={classes.clickablePartOfBar} onClick={toggleOpen}>
        <ExpandMoreIcon
          onClick={toggleOpen}
          className={classNames(classes.expandIcon, {
            [classes.openIcon]: state.open,
            [classes.notOpenIcon]: !state.open,
            [classes.disabledColor]: alwaysClosed
          })}
          />
          <Typography className={classNames(classes.heading, {
            [classes.disabledColor]: alwaysClosed
          })} color="textPrimary">{title}</Typography>
        </div>
        {control ? <div className={classes.control}>{control}</div> : <div />}
      </div>
      {/* <div
        className={classNames(classes.panel, {
          [classes.openPanel]: open,
          [classes.notOpenPanel]: !open
        })}
      >
        <div ref={panelElement}>
          {props.children}
        </div>
      </div> */}
      <Collapse isOpened={state.open}>{props.children}</Collapse>
    </div>
  );
};
export default ExpansionPanel;
