import React from 'react';

const Landing = props => {
  return (
    <div>
      <h1>Landing</h1>
    </div>
  );
};

export default Landing;
