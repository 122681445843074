import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const backgroundStyles = makeStyles(theme => ({
  root: {
    zIndex: '1000',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
}));

const ModalBG = WrappedComponent => props => {
  const { isOpen, close } = props;
  const classes = backgroundStyles();

  return (
    isOpen && (
      <div className={classes.root} onClick={close}>
        <WrappedComponent {...props} />
      </div>
    )
  );
};

export default ModalBG;
