import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { graphql } from 'react-apollo';
import { QUERY_SERVICE_PROVIDER_CUSTOMERS } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Table from 'screens/Customers/table';
import Details from 'screens/Customers/details';
import { ROUTES } from 'components/Routes';
import { customerFields, defaultColumns } from 'constants/customerDataFields';
import ErrorBoundary from "components/ErrorBoundary"

// manages the route state and holds the table and details components
// injects and stores the graphql data on a customer
// have decided to render the details as a page and not as a modal
// inputs:
//   --history
//   --customers
//   --customerFields
//   --defaultColumns
const CustomersPage = props => {
  const {
    data: { customers = [] },
    history: { push = () => {}, location: { state: { screen } = {} } = { state: {} } }
  } = props;

  // the devices a customer has is a sub-object of customer making it akward to display in the table
  // the properties of item.devices are coppied to item for easy display,
  // and the properties not displayd in the table are deleted
  const tableData = customers.map(item => {
    const { devices = null } = item;
    const row = Object.assign({}, item, devices);
    delete row.devices;
    delete row.property;
    return row;
  });

  const [detailsCustomer, setDetailsCustomer] = useState(undefined);

  // This function is passed to the table
  // when a user clicks a row it takes them to the details
  const goToDetails = rowId => {
    setDetailsCustomer(customers[rowId]);
    const redirectPath = ROUTES.CUSTOMERS.screens.DETAILS;
    push(redirectPath.pathname, redirectPath.state);
  };

  // This function is passed to the table
  // when a user clicks the icon it deletes the customer account?
  // must have a confirmation step before deleting
  const deleteCustomer = rowId => {
    return;
  };

  switch (screen) {
    case ROUTES.CUSTOMERS.screens.DETAILS.state.screen:
      const errorRedirectPath = ROUTES.CUSTOMERS.screens.INDEX;
      return (
        <ErrorBoundary
          alertText='Error getting customer details.'
          redirectTo={{ pathname: errorRedirectPath.pathname, state: errorRedirectPath.state}}
        >
          <Details customerFields={customerFields} customer={detailsCustomer} />
        </ErrorBoundary>
      )
    default:
      return (
        <Table
          customerFields={customerFields}
          defaultColumns={defaultColumns}
          data={tableData}
          goToDetails={goToDetails}
          deleteCustomer={deleteCustomer}
        />
      );
  }
};

CustomersPage.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object
};

CustomersPage.defaultProps = {
  data: {},
  history: {}
};

export default compose(
  withRouter,
  graphql(QUERY_SERVICE_PROVIDER_CUSTOMERS)
)(CustomersPage);
