import React, { useContext, useState } from 'react';
import { compose } from 'recompose';
import Link from '@material-ui/core/Link';
import { AuthContext } from 'components/Amplify';
import { Query, ApolloConsumer, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { QUERY_SERVICE_PROVIDER_USER } from 'constants/GraphQLData';
import Amplify, { Auth, Hub } from 'aws-amplify';

const ProfilePage = props => {
  const { user = null, userId = null, role = null, permissions = [] } = useContext(AuthContext);
  console.log(userId);

  return (
    <ProfileWithData userId={userId} />
  );
};
export default ProfilePage;

const Profile = props => {
  const { user = null, userId = null, role = null, permissions = [] } = useContext(AuthContext);

  const { data, data: { serviceProviderUser: { primaryAddress = '' } = {} } = {} } = props;
  console.log(data);

  // console.log(JSON.parse(localStorage.getItem('permissions')));

  return (
    <div>
      <h1>
        Profile:
        {user}
      </h1>
      <Link href="/passwordchange" variant="body2">
        Change Password
      </Link>
      <div>
        <p>
          Address:
          {primaryAddress}
        </p>
      </div>
    </div>
  );
};

const ProfileWithData = graphql(QUERY_SERVICE_PROVIDER_USER, {
  options: props => ({
    variables: { userId: props.userId }
  })
})(Profile);
