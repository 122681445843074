import { I18n } from 'aws-amplify';

const dict = {
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire"
  }
};

export default dict;
