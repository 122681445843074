import gql from 'graphql-tag';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import QUERY_SERVICE_PROVIDER_USER from 'constants/GraphQLData/user';
import QUERY_SERVICE_PROVIDER_CUSTOMERS, { QUERY_CUSTOMERS_BY_EMAIL } from 'constants/GraphQLData/customers';
import QUERY_IMAGES, { QUERY_BANNER_IMAGES, QUERY_FULLSCREEN_IMAGES } from 'constants/GraphQLData/images';
import {
  QUERY_MESSAGES_DRAFTS_FOR_TABLE,
  MUTATION_UPDATE_DRAFT,
  MUTATION_CREATE_MESSAGE,
  MUTATION_EDIT_MESSAGE,
  QUERY_MESSAGE,
  QUERY_DRAFT
} from 'constants/GraphQLData/messages';

export const getCacheKey = object => {
  switch (object.__typename) {
    case 'userId':
      return `${object.__typename}:${object.userId}`;
    default:
      return defaultDataIdFromObject(object);
  }
};

export {
  QUERY_SERVICE_PROVIDER_USER,
  QUERY_SERVICE_PROVIDER_CUSTOMERS,
  QUERY_CUSTOMERS_BY_EMAIL,
  QUERY_IMAGES,
  QUERY_BANNER_IMAGES,
  QUERY_FULLSCREEN_IMAGES,
  QUERY_MESSAGES_DRAFTS_FOR_TABLE,
  MUTATION_UPDATE_DRAFT,
  MUTATION_CREATE_MESSAGE,
  MUTATION_EDIT_MESSAGE,
  QUERY_MESSAGE,
  QUERY_DRAFT
};