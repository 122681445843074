import React, { useState } from 'react';
import _ from 'lodash';
import MaterialTable from 'material-table';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { graphql } from 'react-apollo';
import { QUERY_BANNER_IMAGES } from 'constants/GraphQLData';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Table from 'screens/Customers/table';
import Details from 'screens/Customers/details';
import { ROUTES } from 'components/Routes';
import { customerFields, defaultColumns } from 'constants/customerDataFields';
import ExpansionPanel from 'screens/MessageCenter/Create/expansionPanel';
import ImageSelect from 'screens/MessageCenter/Create/imageSelect';
import BannerPreview from 'screens/MessageCenter/Create/bannerPreview';
// import FlexFrame from 'screens/MessageCenter/Create/flexFrame';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';
import TextEditor from 'components/Custom/TextEditor';
import CharLimit from 'screens/MessageCenter/Create/charLimit'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '20px 0px',
    margin: 'auto',
    width: '80%',
  },
  inputs: {

  },
  preview: {

  },
  'inputs, preview': {

  },
  wrapper: {
    margin: '0px 40px 0px 0px',
    padding: 5,
    border: `1px solid ${theme.palette.primary.highLight}`
  },
  editor: {
    margin: 0,
    padding: 0,
    verticalAlign: 'bottom',
    width: 500,
    height: 150,
    border: `1px solid ${theme.palette.primary.highLight}`
  },

}));

const Banner = props => {
  const classes = useStyles();

  const {
    title = '',
    data: {
      images: {
        baseURL = '',
        genericImages: { banner: GBIms = [] } = {},
        serviceProviderImages: { banner: SPBIms = [] } = {}
      } = {}
    } = {},
    bannerText = '',
    setBannerText,
    bannerImage = 'notGiven',
    setBannerImage,
    disabled = false,
    maxLength = 0,
  } = props;
  
  let images = [...SPBIms, ...GBIms];
  images = images.map(item => {
    return baseURL + item;
  });

  const { length = 0 } = bannerText;

  return (
    <div className={classes.root}>
      <div className={classes.inputs}>
        <div style={{ marginBottom: 30 }}>Title: {title}</div>
        <div className={classes.wrapper}>
          <textarea disabled={disabled} className={classes.editor} value={bannerText} onChange={setBannerText} />
        </div>
        <CharLimit length={length} maxLength={maxLength} width={512} />
        <div style={{height: 32}} />
        <ImageSelect
          text="Banner Image"
          images={images}
          selectedImage={bannerImage}
          setSelectedImage={setBannerImage}
        />
      </div>
      <div className={classes.preview}>
        <BannerPreview />
      </div>
    </div>
  );
};

Banner.propTypes = {};

Banner.defaultProps = {};

export default compose(
  graphql(QUERY_BANNER_IMAGES)
)(Banner);
