import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  QUERY_MESSAGES_DRAFTS_FOR_TABLE,
  QUERY_MESSAGE,
  QUERY_DRAFT
} from 'constants/GraphQLData';

// this function is called in every useMutation hook, to update the table
// and also update the message/draft in cache
export const updateTable = (cache, { data }, mode = 'NONE') => {
  // console.log(mode)
  // console.log(data)

  let replyData = {};

  switch (mode) {
    case 'NEW_DRAFT':
    case 'EDIT_DRAFT':
      ({ updateDraft: replyData } = data);
      break;
    case 'NEW_MESSAGE':
      ({ createMessage: replyData } = data);
      break;
    case 'EDIT_MESSAGE':
      ({ editMessage: replyData } = data);
      break;
    default:
      return;
  }

  const {
    messageLabel,
    messageId,
    draftId,
    messageType,
    createdAt,
    createdBy,
    texts: {
      subjectText
    } = {},
  } = replyData;

  // console.log(replyData);

  const { messages = [], drafts = [] } = cache.readQuery({ query: QUERY_MESSAGES_DRAFTS_FOR_TABLE });
  switch (mode) {
    case 'NEW_DRAFT':
      drafts.push({
        messageLabel,
        draftId,
        messageType,
        createdAt,
        createdBy,
        texts: {
          subjectText: subjectText
        }
      })
      cache.writeQuery({
        query: QUERY_MESSAGES_DRAFTS_FOR_TABLE,
        data: { messages, drafts }
      });
      cache.writeQuery({
        query: QUERY_DRAFT,
        variables: { id: draftId },
        data: { drafts: [replyData] }
      });
      break;
    case 'EDIT_DRAFT':
      const index1 = _.findIndex(drafts, { draftId: draftId });
      drafts[index1] = {
        messageLabel,
        draftId,
        messageType,
        createdAt,
        createdBy,
        texts: {
          subjectText: subjectText
        }
      }
      cache.writeQuery({
        query: QUERY_MESSAGES_DRAFTS_FOR_TABLE,
        data: { messages, drafts }
      });
      cache.writeQuery({
        query: QUERY_DRAFT,
        variables: { id: draftId },
        data: { drafts: [replyData] }
      });
      break;
    case 'NEW_MESSAGE':
      messages.push({
        messageLabel,
        messageId,
        messageType,
        createdAt,
        createdBy,
        texts: {
          subjectText: subjectText
        }
      })
      cache.writeQuery({
        query: QUERY_MESSAGES_DRAFTS_FOR_TABLE,
        data: { messages, drafts }
      });
      cache.writeQuery({
        query: QUERY_MESSAGE,
        variables: { id: messageId },
        data: { messages: [replyData] }
      });
      break;
    case 'EDIT_MESSAGE':
      const index2 = _.findIndex(messages, { messageId: messageId });
      messages[index2] = {
        messageLabel,
        messageId,
        messageType,
        createdAt,
        createdBy,
        texts: {
          subjectText: subjectText
        }
      }
      cache.writeQuery({
        query: QUERY_MESSAGES_DRAFTS_FOR_TABLE,
        data: { messages, drafts }
      });
      cache.writeQuery({
        query: QUERY_MESSAGE,
        variables: { id: messageId },
        data: { messages: [replyData] }
      });
      break;
    default:
      return;
  }
  // console.log({ messages, drafts });
  
}