import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  Typography,
  Paper,
  TableCell,
  TableRow,
  Box,
  ButtonBase,
  Radio,
  Table,
  TableBody,
  Divider
} from '@material-ui/core';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';

const radioSelectGroupStyles = makeStyles(theme => ({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.highLight
    }
  },
  selectedRow: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.highLight
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  flex1: {
    flex: 1
  },
  verticalCenterParent: {
    // position: 'relative'
  },
  verticalCenterChild: {
    padding: '11px 0',
    // position: 'absolute',
    // top: '50%',
    // transform: 'translateY(-50%)',
  }
}));

// a radio select group
// inputs:
// -- titles: an array of texts to display beside the radios
//        (the index identifies the selected item)
// -- field:
//        an optional string that is used if titles is passed in as an array of objects
//        to avoid the inconvenience of extracting the array of titles from the data.
//        "field" identifies the property name corresponding to the title
// -- selectedIndex:
//        the index of the currently selected item
// -- setSelectedIndex:
//        a callback passed from the parent that takes the key as the input
//        and sets the selected index in the parent component
const RadioSelectGroup = props => {
  const classes = radioSelectGroupStyles();
  const { titles, field, selectedIndex, setSelectedIndex } = props;

  const handleSelect = index => {
    setSelectedIndex(index);
  };

  // this is the function that extracts out the title if a "field" value is passed in as a prop
  const t = title => {
    if (field) {
      return title[field];
    }
    return title;
  };

  return (
    <ul className={classes.ul}>
      {titles.map((title, index) => {
        // checks if the row is selected, so that the selected one can be highlighted
        let clName = 'row';
        if (index === selectedIndex) {
          clName = 'selectedRow';
        }
        return (
          <li key={t(title)} className={classes[clName]} onClick={() => handleSelect(index)}>
            <Divider />
            <div className={classes.flex}>
              <div className={classes.flex1}>
                <Radio checked={selectedIndex === index} />
              </div>
              <div className={classes.verticalCenterParent}>
                <Box fontWeight="fontWeightLight" className={classes.verticalCenterChild}>{t(title)}</Box>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

RadioSelectGroup.propTypes = {
  titles: PropTypes.string.isRequired,
  field: PropTypes.string,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func.isRequired
};

RadioSelectGroup.defaultProps = {
  field: undefined,
  selectedIndex: 0
};

export default RadioSelectGroup;
