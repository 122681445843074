import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { graphql } from 'react-apollo';
import {
  CustomPaper,
  CustomHeading,
  CustomDataItem,
  CustomClickableDataItem
} from 'components/Custom/customStyledComponents';

const useStyles = makeStyles(theme => ({
  modal: {
    width: 500,
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  center: {
    textAlign: 'center',
  }
}));

// shows some data on a device or devices of the same kind
// child of propertyDetails, and rendered in a modal
// inputs:
//   -- data
//   --
//   --
const DeviceData = props => {
  const classes = useStyles();
  const {
    data,
  } = props;

  return (
    <div>
      <CustomPaper className={classes.modal}>
        <CustomHeading>Device Data</CustomHeading>
        <div className={classes.center}>
          <p>Coming soon...</p>
        </div>
      </CustomPaper>
    </div>
  );
};

DeviceData.propTypes = {
  data: PropTypes.object,
};

DeviceData.defaultProps = {
  data: {},
};

export default DeviceData;

