import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTES } from 'components/Routes';
import DetailsOfCustomer from 'screens/Customers/DetailsOfCustomer/index';
import Properties from 'screens/Customers/DetailsOfProperties/index';
import { propertyFields } from 'constants/customerDataFields';
import { isEmpty } from 'lodash';
import { CustomPaper, CustomHeading, CustomDataItem } from 'components/Custom/customStyledComponents';

const useStyles = makeStyles(theme => ({
  frame: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  flex1: {
    flex: 1
  }
}));

// This component renders in place of the property details if there are no properties
const HasNoProperties = () => {
  // const classes = useStyles();
  return (
    <CustomPaper>
      <CustomHeading>
        There are no properties associated with this customer account.
      </CustomHeading>
    </CustomPaper>
  );
}

// the screen that displays a customers details, including their properties
// child of Customers/index
// inputs:
//   -- customer: the object with a customers data
//   -- allColumns: the object that maps data fields to display titles
const Details = props => {
  const {
    history: { push },
    customer,
    customerFields,
    customer: { property = [] } = {}
  } = props;
  const classes = useStyles();

  // hasProperties decides weather to render all of the info in the properties box
  // otherwise it will render a note saying there are no properties
  const hasProperties = !isEmpty(property);

  // if no data, then the browser probablly refreshed. redirect back to Customers table view:
  if (!customer) {
    const redirectPath = ROUTES.CUSTOMERS.screens.INDEX;
    // push(redirectPath.pathname, redirectPath.state); // push didn't work: did change the route state but didn't refresh the component
    return (
      <Redirect
        to={{
          pathname: redirectPath.pathname,
          state: redirectPath.state
        }}
      />
    );
  }

  // **CREATE FAKE DEVICES DATA ASSOCIATED WITH A PROPERTY FOR NOW
  // **THE DATA WILL BE CHANGED IN THE BACKEND
  property.forEach(item => {
    item.devices = customer.devices;
  });

  return (
    <div className={classes.frame}>
      <div className={classes.flex1}>
        <DetailsOfCustomer customerFields={customerFields} customer={customer} />
      </div>
      <div className={classes.flex1}>
        {hasProperties ? (
          <Properties propertyFields={propertyFields} property={property} />
        ) : (
          <HasNoProperties />
        )}
      </div>
    </div>
  );
};

Details.propTypes = {
  history: PropTypes.object,
  customer: PropTypes.object,
  customerFields: PropTypes.object
};

Details.defaultProps = {
  history: {},
  customer: undefined,
  customerFields: {}
};

export default compose(withRouter)(Details);
