import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#0C0E27',
      main: '#28316D',
      light: '#5091BD',
      highLight: '#D3E4EF',
      contrastText: '#CECECE'
    },
    secondary: {
      main: '#5295C0'
    },
    default: {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#EEEEEE',
      contrastText: '#434343'
    },
    grey: {
      light: '#EEEEEE',
      main: '#BDBDBD',
      disabled: '#BDBDBD'
    }
  }
});

export default theme;
