import React, { useState } from 'react';
import { Radio, Typography, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { CustomHeading, } from 'components/Custom/customStyledComponents';
import { CSVFetchUsersModal } from 'screens/MessageCenter/Create/CSVFetchUsers'
import CustomButton from 'components/Custom/CustomButton';
import ModalDialog from 'components/Custom/modalDialog';

const radioWithSelectStyles = makeStyles(theme => ({
  root: {
    margin: '10px 0px',
    width: '50%',
    border: `2px solid ${theme.palette.grey.light}`,
    borderRadius: 22,
  },
  selected: {
    borderColor: theme.palette.primary.light,
  },
  flex1: {
    flex: 1
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    padding: '9px 0px',
    fontWeight: theme.typography.fontWeightRegular
  },
  centerOuter: {
    position: 'relative',
    height: 42,
  },
  centerInner: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: '50 %',
    transform: 'translateY(-50 %)'
  },
}));

const RadioWithSelect = props => {
  const classes = radioWithSelectStyles();
  const { title = '', children, checked = false, disabled=false, onChange, value } = props

  return (
    <div className={classNames(classes.root, classes.flex, { [classes.selected]: checked })}>
      <div className={classes.flex}>
        <Radio checked={checked} disabled={disabled} onChange={onChange} value={value} />
        <Typography className={classNames(classes.heading)} color="textPrimary">{title}</Typography>
          <div>
            {children}
          </div>
      </div>
    </div>
  );
}

const DeliverySection = props => {
  const {
    stage,
    timeStart,
    timeEnd,
    setTimeStart,
    setTimeEnd,
  } = props

  const [timeStartRadioValue, setTimeStartRadioValue] = useState(timeStart ? 'schedule' : 'immediate');
  const [timeEndRadioValue, setTimeEndRadioValue] = useState(timeEnd ? 'schedule' : 'never');

  const scheduleStartDisabled = timeStartRadioValue !== 'schedule';
  const immediateStartDisabled = timeStartRadioValue !== 'immediate';
  const scheduleEndDisabled = timeEndRadioValue !== 'schedule';
  const neverEndDisabled = timeEndRadioValue !== 'never';

  return (
    <div>
      <CustomHeading medium>Delivery</CustomHeading>
      <div style={{ display: 'flex' }}>
        <div>
          <InputLabel shrink>Starts at:</InputLabel>
          <div style={{ display: 'flex' }}>
            <Radio
              value={null}
              checked={false}
              onChange={null}
            />
            <span style={{ paddingTop: 12 }}>Immediately</span>
          </div>
          <div style={{ display: 'flex' }}>
            <Radio
              value={null}
              checked={false}
              onChange={null}
            />
            <span style={{ paddingTop: 12 }}>Schedule for</span>
            <div style={{ width: 10 }} />
            <TextField type="date" value={null} onChange={null} style={{ paddingTop: 6 }} />
            <div style={{ width: 10 }} />
            <TextField type="time" value={null} onChange={null} style={{ paddingTop: 6 }} />
          </div>
        </div>
        <div style={{ width: 60 }} />
        <div>
          <InputLabel shrink>Ends at:</InputLabel>
          <div style={{ display: 'flex' }}>
            <Radio
              value={null}
              checked={false}
              onChange={null}
            />
            <span style={{ paddingTop: 12 }}>Never</span>
          </div>
          <div style={{ display: 'flex' }}>
            <Radio
              value={null}
              checked={false}
              onChange={null}
            />
            <span style={{ paddingTop: 12 }}>Schedule for</span>
            <div style={{ width: 10 }} />
            <TextField type="date" value={null} onChange={null} style={{ paddingTop: 6 }} />
            <div style={{ width: 10 }} />
            <TextField type="time" value={null} onChange={null} style={{ paddingTop: 6 }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const ConfirmRemoveGroupOfUsersModal = props => {
  const { isOpen = false, close, setToAll } = props

  return (
    <ModalDialog
      title='Message Distribution - All Users'
      isOpen={isOpen}
      close={close}
    >
      <div>
        <p>
          Are you sure you want to change the distribution of this message to All Users?  
          The list of recipients you set previously will be discarded as a result.
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <CustomButton
            onClick={close}
            text="Cancel"
          />
          <CustomButton
            text="Yes"
            onClick={setToAll}
            dark
          />
        </div>
      </div >
    </ModalDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20
  },
  upper: {
  },
  flex1: {
    flex: 1
  },
  flex: {
    display: 'flex'
  },
  lower: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20
  },
  rightGroup: {
    display: 'flex'
  },
}));

const BottomUI = props => {
  const classes = useStyles();
  const {
    handleButtonClick,
    editMode = false,
    users = [],
    setUsers,
    stage,
    timeStart,
    timeEnd,
    setTimeStart,
    setTimeEnd,
  } = props;
  const [usersSelectionMethod, setUsersSelectionMethod] = useState(() => { // 'all', 'select', 'group'
    if (!_.isEmpty(users)) { // initial setting is 'select' if the loaded draft has a non-empty users list 
      return 'select';
    } 
    return 'all';
  }); 
  const [CSVFetchUsersIsOpen, setCSVFetchUsersIsOpen] = useState(false);
  const [confirmRemoveGroupOfUsersIsOpen, setConfirmRemoveGroupOfUsersIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(users);

  const setToAll = () => {
    setUsersSelectionMethod('all');
    setSelectedUsers([]);
    setUsers([]);
    setConfirmRemoveGroupOfUsersIsOpen(false)
  };
  
  const handleRadioSelectChange = (evt) => {
    switch (evt.target.value) {
      case 'all':
        if (!_.isEmpty(selectedUsers)) {
          setConfirmRemoveGroupOfUsersIsOpen(true);
        } else {
          setUsersSelectionMethod('all');
          setUsers([]);
        }
        break;
      case 'select':
        setUsersSelectionMethod('select');
        setUsers(selectedUsers);
        break
    }
  };

  if (!editMode) {
    return (
      <div className={classes.root}>
        <CSVFetchUsersModal
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          isOpen={CSVFetchUsersIsOpen}
          close={() => setCSVFetchUsersIsOpen(false)}
          setUsersSelectionMethod={setUsersSelectionMethod}
          setUsers={setUsers}
        />
        <ConfirmRemoveGroupOfUsersModal
          isOpen={confirmRemoveGroupOfUsersIsOpen}
          close={() => setConfirmRemoveGroupOfUsersIsOpen(false)}
          setToAll={setToAll}
        />
        <div className={classes.upper} >
          <CustomHeading medium>Recipients</CustomHeading>
          <span>Select which groups the mesasages should be delivered to.</span>
          <RadioWithSelect
            title="Selected Users:"
            value='select'
            onChange={handleRadioSelectChange}
            checked={usersSelectionMethod === 'select'}
          >
            <div style={{
              display: 'flex'
            }}>
              <div style={{ width: 10 }} />
              <div style={{ paddingTop: 6 }}>
                <CustomButton
                  onClick={() => setCSVFetchUsersIsOpen(true)}
                  text="Set / Review"
                  small
                  dark={usersSelectionMethod === 'select'}
                />
              </div>
              <div style={{ width: 10 }} />
              <span style={{ paddingTop: 12 }} >({selectedUsers ? selectedUsers.length : 0} Users Selected)</span>
            </div>
          </RadioWithSelect>
          <RadioWithSelect
            title="All"
            value='all'
            onChange={handleRadioSelectChange}
            checked={usersSelectionMethod === 'all'}
          ></RadioWithSelect>
          <div style={{ height: 20 }} />
          <DeliverySection
            timeStart={timeStart}
            timeEnd={timeEnd}
            setTimeStart={setTimeStart}
            setTimeEnd={setTimeEnd}
          />
        </div>
        <div className={classes.lower}>
          <CustomButton
            disabled={false}
            onClick={evt => handleButtonClick('cancel')}
            text="Cancel"
          />
          <div className={classes.rightGroup}>
            {/* <CustomButton
              disabled={false}
              onClick={evt => handleButtonClick('preview')}
              text="Preview"
            />
            <div style={{ width: 10 }} /> */}
            <CustomButton
              disabled={false}
              onClick={evt => handleButtonClick('save')}
              dark
              text="Save as Draft"
            />
            <div style={{ width: 10 }} />
            <CustomButton
              disabled={false}
              onClick={evt => handleButtonClick('schedule')}
              text="Schedule"
            />
            <div style={{ width: 10 }} />
            <CustomButton
              disabled={false}
              onClick={evt => handleButtonClick('send')}
              text="Send"
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.lower}>
          <CustomButton
            disabled={false}
            onClick={evt => handleButtonClick('cancel')}
            text="Cancel"
          />
          <div className={classes.rightGroup}>
            <CustomButton
              disabled={false}
              onClick={evt => handleButtonClick('edit')}
              text="Save Changes"
              dark
            />
          </div>
        </div>
      </div>
    );
  }

  
};

BottomUI.propTypes = {};

BottomUI.defaultProps = {};

export default BottomUI;