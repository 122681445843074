import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  Typography,
  Paper,
  TableCell,
  TableRow,
  Box,
  ButtonBase,
  Radio,
  Divider
} from '@material-ui/core';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';

// -----------------------------------------------------------------------------------------------

const paperStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1200,
  },
  narrow: {
    maxWidth: 800,
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
}));

// The paper that is used as a background for displaying information
// the narrow prop decreases the width
export const CustomPaper = props => {
  const classes = paperStyles();
  const { className, narrow } = props; // combining in className from props allows layering styles
  return (
    <div className={classNames(classes.root, { [classes.narrow]: narrow })}>
      <Paper className={classNames(classes.paper, className)}>
        {props.children}
      </Paper>
    </div>
  );
};

CustomPaper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any
};

CustomPaper.defaultProps = {
  children: undefined
};

// -----------------------------------------------------------------------------------------------

const headingStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.light,
  },
  mediumHeading: {
    color: theme.palette.primary.light,
    fontSize: '1.4em',
  },
  smallHeading: {
    color: theme.palette.primary.light,
    fontSize: '1.2em',
  },
  tinyHeading: {
    color: theme.palette.primary.light,
    fontSize: '1.0em',
  }
}));

// the app's style of heading
// the prop variant (from mui) is supposed to let you control the component size but it doesn't work well
// instead use the prop small or medium for a smaller heading
export const CustomHeading = props => {
  const classes = headingStyles();
  const { component, variant, tiny, small, medium, color } = props

  return (
    <Typography
      className={classNames({
        [classes.heading]: !(tiny || small || medium),
        [classes.mediumHeading]: medium,
        [classes.smallHeading]: small,
        [classes.tinyHeading]: tiny
      })}
      component={component}
      variant={variant}
      gutterBottom
      style = {color ? {color: color} : {}}
    >
      {props.children}
    </Typography>
  );
};

CustomHeading.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
  component: PropTypes.string,
  small: PropTypes.bool,
};

CustomHeading.defaultProps = {
  children: undefined,
  component: 'h2',
  variant: 'h5',
  small: false,
};

// -----------------------------------------------------------------------------------------------

const dataItemStyles = makeStyles(theme => ({
  li: {
    '&:last-child': {
      marginBottom: theme.spacing(3),
    }
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.highLight
    }
  },
  selected: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.highLight
  },
  liContent: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  box: {
    // flex: 1,
  }
}));

// parts of some of the lists of data, can be clickable
// **in the DOM tree, this component is to be placed as a child of a ul
// **TODO** Implement ripple effect
// inputs:
// -- title (the text to display)
// -- data (the value to display)
// -- onClick: if a function is passed here it becomes clickable
// -- selected: if true then the li will be highlighted as selected
export const CustomDataItem = props => {
  const classes = dataItemStyles();
  const { data, title, onClick, selected } = props;

  // assign the appropriate class name if the item is clickable or selected
  let clName = classes.li;
  if (selected) {
    clName = classes.selected;
  } else if (onClick) {
    clName = classes.clickable;
  }

  return (
    <li className={clName} onClick={onClick ? onClick : () => {}}>
      <Divider />
      <div className={classes.liContent}>
        <Box fontWeight="fontWeightMedium" className={classNames.box}>{title}</Box>
        <Box fontWeight="fontWeightLight" className={classNames.box}>{data}</Box>
      </div>
    </li>
    
  );
};

CustomDataItem.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

CustomDataItem.defaultProps = {
  title: undefined,
  data: undefined,
  onClick: undefined,
  selected: undefined,
};

// -----------------------------------------------------------------------------------------------