import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import classNames from 'classnames';
import { } from 'components/Custom/customStyledComponents';
import CheckIcon from '@material-ui/icons/Check';

// -----------------------------------------------------------------------------

const backgroundStyles = makeStyles(theme => ({
  root: {
    zIndex: '1000',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
}));

const Background = props => {
  const { children, close } = props
  const classes = backgroundStyles();

  return (
    <div className={classes.root} onClick={close} >
      {children}
    </div>
  );
};

Background.propTypes = {};

Background.defaultProps = {};

// -----------------------------------------------------------------------------

const gridStyles = makeStyles(theme => ({
  root: {
    position: 'static',
    margin: 'auto',
    maxWidth: 1200,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 800
    }
  },
  bar: {
    height: '100px'
  },
  grid: {
    marginTop: 30,
    paddingTop: 10,
    marginBottom: 30,
    height: 'calc(100% - 60px)',
    overflowY: 'scroll',
    display: 'grid',
    gridGap: 60,
    gridTemplateColumns: '180px 180px 180px 180px 180px',
    gridAutoRows: 180,
    justifyContent: 'center',
    '&::-webkit-scrollbar': {
      width: '24px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#a0a0a0',
      borderRadius: 12
    },
    [theme.breakpoints.down('md')]: {
      gridGap: 50,
      gridTemplateColumns: '150px 150px 150px 150px',
      gridAutoRows: 150
    }
  },
  adjustGridHeight: {
    height: 'calc(100% - 100px - 60px)'
  }
}));

const Grid = props => {
  const { children, bar } = props
  const classes = gridStyles();

  return (
    <div className={classes.root}>
      {bar &&
        <div className={classes.bar}>
          {bar}
        </div>
      }
      <div className={classNames(classes.grid, { [classes.adjustGridHeight]: bar })}>
        {children}
      </div>
    </div>
  );
};

Grid.propTypes = {};

Grid.defaultProps = {};

// -----------------------------------------------------------------------------

const topBarStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 50
  }
}));

const TopBar = props => {
  const { children } = props
  const classes = topBarStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

// -----------------------------------------------------------------------------

const gridItemStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  galleryImage: {
    width: 180,
    height: 180,
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 150,
    }
  }
}));

const GridItem = props => {
  const { children, image, selected, onClick } = props
  const classes = gridItemStyles();

  return (
    <div className={classes.root}>
      {
        selected && <Selectmark />
      }
      <img
        className={classes.galleryImage}
        src={image}
        alt="lightbox grid gallery image"
        onClick={onClick}
      />
    </div>
  );
};

GridItem.propTypes = {};

GridItem.defaultProps = {};

// -----------------------------------------------------------------------------

const selectmarkStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    // zIndex: 1001,
    backgroundColor: theme.palette.primary.light,
    width: 20,
    height: 20,
    borderRadius: 10
  },
  checkIcon: {
    color: theme.palette.primary.highLight,
    width: 20,
    height: 20
  }
}));

const Selectmark = props => {
  const {} = props
  const classes = selectmarkStyles();

  return (
    <div className={classes.root}>
      <CheckIcon className={classes.checkIcon} />
    </div>
  );
};

Selectmark.propTypes = {};

Selectmark.defaultProps = {};

// -----------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20
  },
  // upper: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   flexWrap: 'wrap',
  //   paddingBottom: 30,
  // },
  flex1: {
    flex: 1
  }
}));

// const images = [...Array(53).keys()];

const GridGallery = props => {
  const { close, images = [], selectedIndices = [], setSelected } = props
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Background close={close}>
        <Grid close={close} bar={<TopBar />}>
          {images.map((item, index) => (
            <GridItem
              image={item}
              selected={_.includes(selectedIndices, index)}
              onClick={evt => {
                // evt.preventDefault();
                evt.stopPropagation();
                setSelected(index);
              }}
              key={item}
            />))}
        </Grid>
      </Background>
    </div>
  );
};

GridGallery.propTypes = {};

GridGallery.defaultProps = {};

export default GridGallery;
