import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  Button
} from '@material-ui/core';

const styles = makeStyles(theme => ({
  darkButton: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
  lightButton: {
    backgroundColor: theme.palette.grey.light,
    color: 'black',
  },
}));

/* */
const CustomButton = props => {
  const classes = styles();
  const { text = '', onClick = null, dark=false, disabled=false, small=false } = props;

  const buttonVariantClass = dark ? classes.darkButton : classes.lightButton;

  if (small) {
    return (
      <div>
        <Button
          size="small"
          disabled={disabled}
          variant="contained"
          className={classNames(buttonVariantClass)}
          onClick={onClick}>{text}
        </Button>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          disabled={disabled}
          variant="contained"
          className={classNames(buttonVariantClass)}
          onClick={onClick}>{text}
        </Button>
      </div>
    );
  }
  
};

CustomButton.propTypes = {

};

export default CustomButton