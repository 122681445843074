import React from 'react';
import { AuthBouncer } from 'components/Amplify';

const HomePage = () => (
  <div>
    {/* <h1>Home Page</h1>
    <p>The Home Page is accessible by every signed in user.</p> */}
  </div>
);

export default HomePage;
