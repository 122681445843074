import React, { useState, useContext } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { BC_TRAILS, ROUTES } from 'components/Routes';
import { find } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  paper: {}
}));

const BreadcrumbBar = props => {
  const classes = useStyles();
  const DEFAULT_STATE = { screen: 'INDEX' };
  const {
    history: {
      location: { pathname, state = DEFAULT_STATE }
    }
  } = props;

  const BCTrail = (find(BC_TRAILS, { pathname, screen: state.screen }) || { trail: [] }).trail;

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs aria-label="Breadcrumb">
          {BCTrail.map((crumb, index) => {
            const {
              isLink = false,
              data: {
                text,
                pathname,
                state: { screen }
              }
            } = crumb;
            if (isLink) {
              return (
                <Link
                  key={text + pathname + screen}
                  component={RouterLink}
                  color="inherit"
                  to={{
                    pathname: crumb.data.pathname,
                    state: crumb.data.state
                  }}
                >
                  {crumb.data.text}
                </Link>
              );
            }
            return (
              <Typography key={text + pathname + screen} color="textPrimary">
                {crumb.data.text}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Paper>
    </div>
  );
};

BreadcrumbBar.propTypes = {
  history: PropTypes.object
};

export default withRouter(BreadcrumbBar);
